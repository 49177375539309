import { BACKGROUND_COLORS, BORDER_COLORS, BREAKPOINTS, COMPONENTS } from '@/design'
import { motion } from 'framer-motion'

export const SCanvas = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid ${BORDER_COLORS.one};
  backdrop-filter: blur(2px);
  background: ${BACKGROUND_COLORS.two};
  grid-column: 1 / span 5;
  position: relative;
  overflow: hidden;
  z-index: 1;

  /* Vendor prefixes */
  -webkit-backdrop-filter: blur(2px); /* Safari and Chrome */
  -moz-backdrop-filter: blur(2px); /* Firefox */
  -ms-backdrop-filter: blur(2px); /* Edge */

  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    height: ${COMPONENTS.mobileGameView}px;
    backdrop-filter: blur(2px);

    /* Vendor prefixes */
    -webkit-backdrop-filter: blur(2px); /* Safari and Chrome */
    -moz-backdrop-filter: blur(2px); /* Firefox */
    -ms-backdrop-filter: blur(2px); /* Edge */
  }

  @media (max-width: ${BREAKPOINTS.sm}px) and (orientation: landscape) {
    height: 99%;
  }
`

export const SMounting = styled(motion.div)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  /* Vendor prefixes */
  -webkit-backdrop-filter: blur(2px); /* Safari and Chrome */
  -moz-backdrop-filter: blur(2px); /* Firefox */
  -ms-backdrop-filter: blur(2px); /* Edge */
  /* background: black; */
  user-select: none;
`

export const ResizingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`

export const DiceSliderWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 4%;
  z-index: 10;
  width: 93%;
  touch-action: none;
`
