import { useShallow } from 'zustand/react/shallow'
import useSUContractStore from '@/store/useSUContractStore'
import { sendGameStoreUpdateByAddress } from '@/store/useGameStateStore'
import { type GameStateReducer } from '@/lib/fare/state'
import { type BigNumber, type Event, utils } from 'ethers'
import { entryEvent } from '@/events/entryEvent'
import useUserDataStore from '@/store/useUserDataStore'
import { useActiveWallet } from '@/lib/privy/hooks'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { getAppCurrencyDecimals } from '@/chains/lib'
import { usePathGameName } from '@/hooks/usePathGameName'
import useMaxValues from '@/hooks/useMaxValues'
import { useDebounceCallback } from 'usehooks-ts'

export interface Trial {
  gameName: string
  who: string
  submitter: string
  multiplier: BigNumber
  count: number
  q: bigint[]
  k: bigint[]
  filledOriginalQ: bigint[]
  filledOriginalK: bigint[]
  vrfCostInUsdc: BigNumber
  aaCostInUsdc: BigNumber
}

export const useGameContractListener = () => {
  const gameName = usePathGameName()
  const {
    setSubmittedAmount,
    setInProgressEntry,
    setIsSubmitting,
    setHasApprovedContracts,
    setApprovedGameState,
  } = useSUContractStore(
    useShallow(state => ({
      setSubmittedAmount: state.setSubmittedAmount,
      setInProgressEntry: state.setInProgressEntry,
      setIsSubmitting: state.setIsSubmitting,
      setHasApprovedContracts: state.setHasApprovedContracts,
      setApprovedGameState: state.setApprovedGameState,
    }))
  )
  const { walletAddress, walletChainId } = useActiveWallet()
  const appContracts = useAppChainConfigStore(state => state.appContracts)
  const { fetchAndSetSCMaxValues } = useMaxValues()

  /* Memos */
  const send = useMemo(() => sendGameStoreUpdateByAddress(gameName), [gameName]) as
    | GameStateReducer
    | undefined

  // @TODO: Think about what these state settings are doing, also consider not even listening to this
  useEffect(() => {
    if (!appContracts || !appContracts.ws || !walletAddress) return
    const entrySubmittedFilter = appContracts.ws.vault.filters.TrialRegistered(
      null,
      walletAddress,
      null,
      null,
      null,
      null,
      null,
      null
    )

    const entrySubmittedListener = (
      _trialId: BigNumber,
      _who: string,
      _submitter: string,
      multiplier: BigNumber,
      _q: BigNumber[],
      _k: BigNumber[],
      _vrfCostInUsdc: BigNumber,
      _aaCostInUsdc: BigNumber,
      _event: Event
    ) => {
      if (useUserDataStore.getState().currentGameName !== gameName) return
      const formattedTotalEntryAmount = utils.formatUnits(multiplier, getAppCurrencyDecimals())
      setSubmittedAmount(formattedTotalEntryAmount)
      setIsSubmitting(false)
      setInProgressEntry({ requestId: _trialId.toString(), timestamp: Date.now() })
    }

    appContracts.ws.vault.on(entrySubmittedFilter, entrySubmittedListener)

    return () => {
      appContracts.ws.vault.removeListener(entrySubmittedFilter, entrySubmittedListener)
    }
  }, [walletAddress, appContracts, gameName, walletChainId])

  entryEvent.useSub('gameFinished', () => {
    send?.({
      type: 'RESET',
      payload: {},
    })

    setInProgressEntry(null)
    setIsSubmitting(false)
  })

  const checkApprovedGameContracts = useDebounceCallback(async (addr: any) => {
    if (!appContracts?.bankroll || !appContracts?.vault || !addr) return
    setApprovedGameState('pending')

    try {
      const isApproved = await appContracts.ws.bankroll.isValidContractForFundOwner(
        appContracts.vault.address,
        addr
      )

      setApprovedGameState(isApproved ? 'approved' : 'not-approved')
      setHasApprovedContracts(isApproved)
      fetchAndSetSCMaxValues(addr)
    } catch (err) {
      console.error(err)
      setApprovedGameState('not-approved')
    }
  }, 250)

  useEffect(() => {
    checkApprovedGameContracts(walletAddress)
  }, [walletAddress, appContracts])
}
