import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { styled, css } from 'styled-components'
import { BORDER_COLORS, BREAKPOINTS, CHIP_COLORS, TEXT_COLORS } from '@/design'
import {
  FormAmountLabel,
  FormFooter,
  FormIndicator,
  FormLabel,
  FormLabelRow,
  FormWrapper,
  SFormSection,
} from '@/components/Forms/style'
import numeral from 'numeral'
import useCurrencyStore from '@/store/useCurrencyStore'
import { useShallow } from 'zustand/react/shallow'
import { FormLayout } from '@/components/Forms/FormComponents/shared/FormLayout'
import {
  RouletteChip,
  type RouletteEntryHelperForQK,
  useRouletteGameStore,
  type RouletteEntry,
} from '@/store/useRouletteGameStore'
import { motion, AnimatePresence } from 'framer-motion'
import { CheckboxContainer, CheckboxLabel, StyledCheckbox } from './styles'
import { SliderInput } from '@/components/Forms/FormComponents/shared/SliderInput'
import { useRouletteSound } from './RouletteSoundInterface'
import { FARE_COLORS, OUTCOME_COLORS } from '@/design/colors'
import { calculateWinIntensity } from './RouletteScene'
import { GameButton } from '@/components/shared/Button/GameButton'
import {
  convertRouletteUIRepresentationToRouletteNumberToBetFraction,
  type IRouletteSide,
} from '@/lib/crypto/roulette'
import { SVGS } from '@/assets'
import RouletteDrawer from './RouletteDrawer'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { createPortal } from 'react-dom'
import { Button, ButtonEnum } from '@/components/shared/Button'

const ChipButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 8px;
`

const ChipButton = styled.button<{ $isActive: boolean; $value: number; $minBetAmount: number }>`
  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: ${props => {
    const multiplier = props.$value / props.$minBetAmount
    if (multiplier < 5) return `${CHIP_COLORS.one}${props.$isActive ? 'ff' : '99'}`
    else if (multiplier < 25) return `${CHIP_COLORS.five}${props.$isActive ? 'ff' : '99'}`
    else if (multiplier < 100) return `${CHIP_COLORS.twentyFive}${props.$isActive ? 'ff' : '99'}`
    return `${CHIP_COLORS.hundred}${props.$isActive ? 'ff' : '99'}`
  }};
  border: 2px solid ${props => (props.$isActive ? '#ffffff' : CHIP_COLORS.border.normal)};
  box-shadow: ${props =>
    props.$isActive ?
      '0 0 10px rgba(255, 255, 255, 0.5), inset 0 0 8px rgba(0, 0, 0, 0.5)'
    : 'inset 0 0 8px rgba(0, 0, 0, 0.5)'};
  color: ${CHIP_COLORS.text.normal};
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  font-weight: 600;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

  &:first-child {
    &::before {
      content: '';
      position: absolute;
      width: 70%;
      height: 70%;
      border-radius: 50%;
      border: 1.5px dashed blue;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 70%;
    height: 70%;
    border-radius: 50%;
    border: 1.5px dashed white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    border: 1.5px solid ${CHIP_COLORS.border.faded};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover:not(:disabled) {
    transform: scale(1.05);
    border-color: #ffffff;
    opacity: 1;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const CurrentEntriesSection = styled(SFormSection)`
  padding-bottom: 24px;
  border: none;
  max-height: 268px;
  min-height: 268px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 8px;
`

const EntryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 0px;
  overflow-y: auto;
  padding: 0 8px 4px 0;
  margin-right: -8px;
  height: 82%;
  mask-image: linear-gradient(to bottom, black calc(100% - 4px), transparent 100%);

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${BORDER_COLORS.one};
    border-radius: 2px;
  }
`

const EntryItemContainer = styled(motion.div)`
  position: relative;
  overflow: visible;
  min-height: 32px;
`

const EntryItemInner = styled(motion.div)`
  position: relative;
  width: 91%;
  padding-right: 4px;
`

const EntryItem = styled(motion.div)<{ $isWinning?: boolean; $intensity?: number }>`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  font-size: 13px;
  background: ${props => {
    if (!props.$isWinning) return 'rgba(0, 0, 0, 0.2)'
    // Add more visible background based on intensity
    switch (props.$intensity) {
      case 1:
        return 'rgba(26, 185, 26, 0.1)'
      case 2:
        return 'rgba(26, 215, 26, 0.1)'
      case 3:
        return 'rgba(26, 245, 26, 0.1)'
      case 4:
        return 'rgba(255, 215, 0, 0.1)'
      case 5:
        return 'rgba(217, 0, 213, 0.1)'
      default:
        return 'rgba(26, 185, 26, 0.1)'
    }
  }};
  backdrop-filter: blur(5px);
  transition: background 0.2s ease;
  width: 100%;
  position: relative;

  &:hover {
    background: ${props => {
      if (!props.$isWinning) return 'rgba(0, 0, 0, 0.3)'
      // More intense on hover
      switch (props.$intensity) {
        case 1:
          return 'rgba(26, 185, 26, 0.15)'
        case 2:
          return 'rgba(26, 215, 26, 0.15)'
        case 3:
          return 'rgba(26, 245, 26, 0.15)'
        case 4:
          return 'rgba(255, 215, 0, 0.15)'
        case 5:
          return 'rgba(217, 0, 213, 0.15)'
        default:
          return 'rgba(26, 185, 26, 0.15)'
      }
    }};
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border: 1px solid
      ${props => {
        if (!props.$isWinning) return BORDER_COLORS.one
        switch (props.$intensity) {
          case 1:
            return OUTCOME_COLORS.win.one
          case 2:
            return OUTCOME_COLORS.win.two
          case 3:
            return OUTCOME_COLORS.win.three
          case 4:
            return OUTCOME_COLORS.win.four
          case 5:
            return OUTCOME_COLORS.win.five
          default:
            return OUTCOME_COLORS.win.one
        }
      }};
    border-radius: 4px;
    pointer-events: none;
    ${props =>
      props.$isWinning &&
      css<{ $intensity?: number }>`
        box-shadow: 0 0 10px ${props => getIntensityColor(props.$intensity)};
        animation: glowPulse 1.5s infinite;
      `}
  }

  @keyframes glowPulse {
    0% {
      box-shadow: 0 0 10px ${props => getIntensityColor(props.$intensity)};
    }
    50% {
      box-shadow: 0 0 20px ${props => getIntensityColor(props.$intensity, '80')};
    }
    100% {
      box-shadow: 0 0 10px ${props => getIntensityColor(props.$intensity)};
    }
  }
`

const getIntensityColor = (intensity?: number, opacity = '4D') => {
  switch (intensity) {
    case 1:
      return OUTCOME_COLORS.win.one + opacity
    case 2:
      return OUTCOME_COLORS.win.two + opacity
    case 3:
      return OUTCOME_COLORS.win.three + opacity
    case 4:
      return OUTCOME_COLORS.win.four + opacity
    case 5:
      return OUTCOME_COLORS.win.five + opacity
    default:
      return OUTCOME_COLORS.win.one + opacity
  }
}

const EntryChip = styled.div<{ $value: number; $minBetAmount: number }>`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: ${props => {
    const multiplier = props.$value / props.$minBetAmount
    if (multiplier < 5) return CHIP_COLORS.one
    if (multiplier < 25) return CHIP_COLORS.five
    if (multiplier < 100) return CHIP_COLORS.twentyFive
    return CHIP_COLORS.hundred
  }};
  border: ${props =>
    props.$value === props.$minBetAmount ? '2px dashed blue' : '2px dashed white'};
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  font-weight: 600;
  color: ${props => (props.$value === props.$minBetAmount ? 'blue' : CHIP_COLORS.text.normal)};
  flex-shrink: 0;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 1px solid ${CHIP_COLORS.border.normal};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const EntryDescription = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${TEXT_COLORS.two};
  margin-left: 4px;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`

const RemoveButton = styled.button<{ $disabled?: boolean }>`
  background: transparent;
  border: none;
  color: ${TEXT_COLORS.two};
  padding: 2px;
  cursor: ${props => (props.$disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.$disabled ? '0.4' : '0.4')};
  transition: all 0.2s;
  margin-left: auto;
  font-size: 12px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  flex-shrink: 0;

  &:hover:not(:disabled) {
    opacity: ${props => (props.$disabled ? '0.4' : '1')};
    background: ${props => (props.$disabled ? 'transparent' : 'rgba(255, 255, 255, 0.1)')};
  }
`

const EmptyStateContainer = styled(motion.div)`
  position: relative;
  overflow: hidden;
`

const EmptyStateContent = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 12px;
  background: rgba(0, 0, 0, 0.2);
  border: 1px dashed ${BORDER_COLORS.one};
  border-radius: 4px;
  color: ${TEXT_COLORS.two};
  backdrop-filter: blur(5px);
`

const EmptyStateText = styled.div`
  font-size: 13px;
  opacity: 0.8;
  letter-spacing: 0.5px;
`

const ChipBorder = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
`

interface RouletteFormData {
  // not sure exactly what needs to be included for backend purposes
  side: IRouletteSide
  entryAmount: number
  numberOfEntries: number
  stopLoss: number
  stopGain: number

  minBetAmount: number
  selectedChip: RouletteChip
}

const formatChipAmount = (amount: number) => {
  return numeral(amount).format(amount % 1 === 0 ? '0,0' : '0,0.00')
}

// Add setIsDrawerOpen to props
interface RouletteFormProps {
  setIsDrawerOpen: (isOpen: boolean) => void
}

const RouletteFormComponent: React.FC<RouletteFormProps> = ({ setIsDrawerOpen }) => {
  const { control, watch, setValue } = useForm<RouletteFormData>({
    defaultValues: {
      side: {},
      entryAmount: 0,
      numberOfEntries: 1,
      stopLoss: 0,
      stopGain: 0,
      minBetAmount: 1,
      selectedChip: RouletteChip.ONE_X,
    },
  })
  const formData = watch()
  const isMobileScreen = useIsBreakpoint('sm')
  const { playGameSound } = useRouletteSound()
  const { isDrawerOpen, setDrawerOpen } = useRouletteGameStore()

  // Watch only necessary fields to minimize re-renders
  const minBetAmount = watch('minBetAmount')
  // const entryAmount = watch('entryAmount')

  const { selectedBalance } = useCurrencyStore(
    useShallow(state => ({
      selectedBalance: state.balances.currency,
    }))
  )

  const {
    gameState,
    setMinBetAmount,
    selectedChip,
    setSelectedChip,
    currentEntries,
    removeEntry,
    totalBetAmount,
    result,
    isKeepingSelection,
    toggleKeepSelection,
    minBetAmount: minBetAmountStore,
    uiRouletteSide,
  } = useRouletteGameStore()

  useEffect(() => {
    setValue('entryAmount', totalBetAmount)
  }, [setValue, totalBetAmount])

  // const isSubmitDisabled = useMemo(() => {
  //   return gameState !== 'IDLE' || currentEntries.length === 0 || totalBetAmount <= 0
  // }, [gameState, currentEntries.length, totalBetAmount])

  const formatEntryDescription = (entry: RouletteEntry): string => {
    switch (entry.type) {
      case 'straight':
        return `Number ${entry.value}`
      case 'split':
        return `Split ${Array.isArray(entry.value) ? entry.value.join('/') : entry.value}`
      case 'street': {
        const nums = Array.isArray(entry.value) ? entry.value : [entry.value]
        return `Street ${nums[0]}-${nums[nums.length - 1]}`
      }
      case 'corner': {
        const nums = Array.isArray(entry.value) ? entry.value : [entry.value]
        return `Corner ${nums.join('/')}`
      }
      case 'line': {
        const nums = Array.isArray(entry.value) ? entry.value : [entry.value]
        return `Line ${nums[0]}-${nums[nums.length - 1]}`
      }
      case 'dozen': {
        const dozenStartNum = (
          Array.isArray(entry.value) ?
            entry.value
          : [entry.value])[0] as number
        return `Dozen ${dozenStartNum}-${dozenStartNum + 11}`
      }
      case 'column':
        const columnNum = (Array.isArray(entry.value) ? entry.value : [entry.value])[0] as number
        return `Column ${columnNum}`
      case 'redBlack':
        return typeof entry.value === 'string' ? entry.value.toUpperCase() : 'RED/BLACK'
      case 'oddEven':
        return typeof entry.value === 'string' ? entry.value.toUpperCase() : 'ODD/EVEN'
      case 'highLow':
        if (typeof entry.value === 'string') {
          return entry.value.toUpperCase()
        }
        return (
          typeof entry.value === 'number' ?
            entry.value > 18 ?
              'HIGH'
            : 'LOW'
          : 'HIGH/LOW'
        )
      case 'trio': {
        const nums = Array.isArray(entry.value) ? entry.value : [entry.value]
        return `Trio ${nums.join('/')}`
      }
      default:
        return `Unknown bet type: ${entry.type}`
    }
  }

  // sets roulette drawer closed if mobile screen
  useEffect(() => {
    if (isMobileScreen) {
      setDrawerOpen(false)
    } else {
      setDrawerOpen(true)
    }
  }, [isMobileScreen, setDrawerOpen])

  // const handleStartRound = () => {
  //   startRound()
  //   playGameSound('beep', 0.4, 1)
  // }

  const isEntryWinning = (entry: RouletteEntryHelperForQK, result?: number): boolean => {
    if (gameState !== 'RESULT_SHOWN' || !result) return false

    return entry.numbers.includes(result)
  }

  // Update chip selection to also show drawer
  const handleChipSelect = (value: RouletteChip) => {
    setSelectedChip(value)
    setIsDrawerOpen(true)
  }

  // Add effect to handle min bet amount changes
  useEffect(() => {
    // Only update if the value is different from what's in the store
    if (minBetAmount !== minBetAmountStore) {
      // Update the store's min bet amount
      setMinBetAmount(minBetAmount)
      // Reset selected chip when min bet amount changes
      setSelectedChip(null)
    }
  }, [minBetAmount, minBetAmountStore, setMinBetAmount, setSelectedChip])

  return (
    <FormWrapper>
      <FormLayout>
        <SliderInput
          label='Minimum Bet Amount'
          label2={`${numeral(selectedBalance).format('0,0.00')}`}
          name='minBetAmount'
          control={control}
          min={1}
          max={10}
          step={1}
          defaultValue={1}
        />

        <SFormSection>
          <FormLabelRow>
            <FormLabel
              $isRelative
              style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
            >
              <div>
                <FormIndicator $isActive={true} style={{ left: -12, top: 4 }} />
                <FormAmountLabel>Selected Chip</FormAmountLabel>
              </div>
            </FormLabel>
          </FormLabelRow>
          <Controller
            name='selectedChip'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <ChipButtonsContainer>
                <ChipButton
                  type='button'
                  $isActive={selectedChip === minBetAmount}
                  $value={minBetAmount}
                  $minBetAmount={minBetAmount}
                  onClick={() => {
                    field.onChange(minBetAmount)
                    handleChipSelect(minBetAmount)
                    playGameSound('beep', 0.2, 0.9)
                  }}
                  style={{ color: FARE_COLORS.blue }}
                >
                  <ChipBorder src={SVGS.level4Icon} />
                  {numeral(minBetAmount).format('0,0')}
                </ChipButton>
                <ChipButton
                  type='button'
                  $isActive={selectedChip === minBetAmount * 5}
                  $value={minBetAmount * 5}
                  $minBetAmount={minBetAmount}
                  onClick={() => {
                    field.onChange(minBetAmount * 5)
                    handleChipSelect(minBetAmount * 5)
                    playGameSound('beep', 0.3, 1)
                  }}
                >
                  <ChipBorder src={SVGS.chipBorder} />
                  {numeral(minBetAmount * 5).format('0,0')}
                </ChipButton>
                <ChipButton
                  type='button'
                  $isActive={selectedChip === minBetAmount * 25}
                  $value={minBetAmount * 25}
                  $minBetAmount={minBetAmount}
                  onClick={() => {
                    field.onChange(minBetAmount * 25)
                    handleChipSelect(minBetAmount * 25)
                    playGameSound('beep', 0.35, 1.1)
                  }}
                >
                  <ChipBorder src={SVGS.chipBorder} />
                  {numeral(minBetAmount * 25).format('0,0')}
                </ChipButton>
                <ChipButton
                  type='button'
                  $isActive={selectedChip === minBetAmount * 100}
                  $value={minBetAmount * 100}
                  $minBetAmount={minBetAmount}
                  onClick={() => {
                    field.onChange(minBetAmount * 100)
                    handleChipSelect(minBetAmount * 100)
                    playGameSound('beep', 0.4, 1.2)
                  }}
                >
                  <ChipBorder src={SVGS.chipBorder} />
                  {numeral(minBetAmount * 100).format('0,0')}
                </ChipButton>
              </ChipButtonsContainer>
            )}
          />
        </SFormSection>

        <CurrentEntriesSection>
          <FormLabelRow>
            <FormLabel
              $isRelative
              style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
            >
              <div>
                <FormIndicator
                  $isActive={currentEntries.length > 0}
                  style={{ left: -12, top: 4 }}
                />
                <FormAmountLabel>Current Entries</FormAmountLabel>
              </div>
              <FormAmountLabel>TOTAL: {formatChipAmount(totalBetAmount)}</FormAmountLabel>
            </FormLabel>
          </FormLabelRow>
          <EntryList>
            <AnimatePresence mode='popLayout'>
              {currentEntries.length === 0 ?
                <EmptyStateContainer
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <EmptyStateContent>
                    <EmptyStateText>No entries placed</EmptyStateText>
                  </EmptyStateContent>
                </EmptyStateContainer>
              : currentEntries.map((entry, index) => (
                  <EntryItemContainer
                    key={`${entry.type}-${Array.isArray(entry.value) ? entry.value.join('-') : entry.value}`}
                    initial={{ height: 0 }}
                    animate={{ height: 'auto' }}
                    exit={{ height: 0 }}
                    transition={{
                      type: 'spring',
                      stiffness: 500,
                      damping: 30,
                      mass: 1,
                    }}
                    layout
                  >
                    <EntryItemInner
                      initial={{ scaleX: 0 }}
                      animate={{ scaleX: 1 }}
                      exit={{ scaleX: 0 }}
                      transition={{
                        type: 'spring',
                        stiffness: 400,
                        damping: 25,
                        mass: 0.8,
                      }}
                      style={{ originX: 0 }}
                    >
                      <EntryItem
                        $isWinning={isEntryWinning(entry, result)}
                        $intensity={calculateWinIntensity(entry)}
                      >
                        <EntryChip $value={entry.amount} $minBetAmount={minBetAmount}>
                          {numeral(entry.amount).format('0,0')}
                        </EntryChip>
                        <EntryDescription>{formatEntryDescription(entry)}</EntryDescription>
                        <RemoveButton
                          onClick={() => gameState === 'IDLE' && removeEntry(index)}
                          title='Remove bet'
                          $disabled={gameState !== 'IDLE'}
                        >
                          ✕
                        </RemoveButton>
                      </EntryItem>
                    </EntryItemInner>
                  </EntryItemContainer>
                ))
              }
            </AnimatePresence>
          </EntryList>
          <CheckboxContainer>
            <CheckboxLabel htmlFor='keepSelectionCheckbox'>KEEP SELECTION</CheckboxLabel>
            <StyledCheckbox
              type='checkbox'
              id='keepSelectionCheckbox'
              checked={isKeepingSelection}
              onChange={toggleKeepSelection}
            />
          </CheckboxContainer>
        </CurrentEntriesSection>
      </FormLayout>
      <FormFooter
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          justifyContent: 'space-between',
        }}
      >
        {isMobileScreen && (
          <>
            <Button
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              buttonType={ButtonEnum.BASE}
              disabled={false}
            >
              Open Roulette Drawer
            </Button>
            {isDrawerOpen && createPortal(<RouletteDrawer />, document.body)}
          </>
        )}
        <GameButton
          formData={{
            ...formData,
            side: {
              uiRepresentation: uiRouletteSide,
              rouletteNumberToBetFraction:
                convertRouletteUIRepresentationToRouletteNumberToBetFraction(uiRouletteSide),
            },
          }}
          entryAmountNum={totalBetAmount}
        />
      </FormFooter>
    </FormWrapper>
  )
}
export const RouletteForm = memo(RouletteFormComponent)
