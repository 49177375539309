import ModalCard from '@/components/shared/Modal/Card'
import { useSnapshot } from 'valtio'
import { SVGS } from '@/assets'
import { AnimatePresence, motion } from 'framer-motion'
import { ModalBody, ModalFooter } from '@/components/shared/Modal/styles'
import { Button, ButtonEnum } from '@/components/shared/Button'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { useGeoblockModal, geoblockModalState } from '@/hooks/useGeoblockModal'

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 16px 0;
  margin-top: 24px;

  .question-bubble {
    position: absolute;
    left: 55%;
    top: 0px;
  }

  .discord-icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 15px;
  }
`

export default function GeoblockModal() {
  const { isVisible, region } = useSnapshot(geoblockModalState)
  const { hideGeoblockModal } = useGeoblockModal()
  const isMobileScreen = useIsBreakpoint('sm')

  const handleClose = () => {
    hideGeoblockModal()
  }

  const joinDiscordServer = () => {
    window.open('https://discord.com/invite/eUEwY3vS8R', '_blank')?.focus()
  }

  return (
    <AnimatePresence>
      <ModalCard
        title={`Betting is blocked in ${region ? region : 'your region'}`}
        description={`If you're using a VPN, ensure your not connected to a region where betting is blocked. If you need help, please join our Discord to get help.`}
        isVisible={isVisible}
        setIsVisible={handleClose}
        className='support-modal'
      >
        <ModalBody className='support-modal-content'>
          <CloseButton onClick={handleClose}>
            <img src={SVGS.closeIcon} width={24} alt='close' />
          </CloseButton>
          Our dedicated community is available to assist you with any challenges you may encounter.
          <ImageWrapper>
            <motion.img
              src={SVGS.questionMarkIcon}
              width={isMobileScreen ? 18 : 24}
              alt='question-icon'
              className='question-bubble'
              animate={{ y: [0, -2, 0] }}
              transition={{ duration: 0.5, delay: 0.5 }}
            />
            <img
              src={SVGS.discordIcon}
              width={isMobileScreen ? 60 : 80}
              alt='discord-logo'
              className='discord-icon'
            />
          </ImageWrapper>
        </ModalBody>
        <ModalFooter className='submit-button'>
          <Button onClick={joinDiscordServer} buttonType={ButtonEnum.BASE} disabled={false}>
            Join our Discord
          </Button>
        </ModalFooter>
      </ModalCard>
    </AnimatePresence>
  )
}
