import { default as ParticlesElement, initParticlesEngine } from '@tsparticles/react'
import { loadSlim } from '@tsparticles/slim'

import { FARE_COLORS } from '@/design'
import { ParticlesWrapper } from './style'

export const ParticlesBackground = memo(() => {
  const [init, setInit] = useState(false)

  useEffect(() => {
    initParticlesEngine(async engine => {
      await loadSlim(engine)
    }).then(() => {
      setInit(true)
    })
  }, [])

  const options = useMemo(() => {
    return {
      fpsLimit: 120,
      pauseOnBlur: true,
      pauseOnOutsideViewport: true,
      particles: {
        color: {
          value: [
            FARE_COLORS.blue,
            FARE_COLORS.peach,
            FARE_COLORS.pink,
            FARE_COLORS.salmon,
            FARE_COLORS.aqua,
          ],
        },
        collisions: {
          enable: true,
        },
        move: {
          enable: true,
          random: false,
          speed: 0.5,
          direction: 'top',
        },
        number: {
          value: 200,
        },
        opacity: {
          value: 1,
        },
        shape: {
          type: 'circle',
        },
        size: {
          value: { min: 0.5, max: 0.75 },
        },
      },
    } as any
  }, [])

  return (
    <ParticlesWrapper>
      {init && <ParticlesElement id='tsparticles' options={options} />}
    </ParticlesWrapper>
  )
})
