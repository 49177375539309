import { useSound } from '@/components/shared/SoundSystem/SoundContext'
import { useEffect, useCallback } from 'react'

// import sfx - we'll reuse some existing sounds and adjust pitch/volume
import beepSound from '@/assets/audio/mouse-over-slider.wav'
import winSound from '@/assets/audio/bombs-win.wav'
import loseSound from '@/assets/audio/bombs-lose.wav'
import clickSound from '@/assets/audio/Click 11.wav'
import coinSound1 from '@/assets/audio/coins/Coins 09.wav'
import coinSound2 from '@/assets/audio/coins/Coins 10.wav'
import coinSound3 from '@/assets/audio/coins/Coins 19.wav'

export const GAME_SOUNDS = {
  beep: beepSound,
  win: winSound,
  lose: loseSound,
  click: clickSound,
  coin1: coinSound1,
  coin2: coinSound2,
  coin3: coinSound3,
}

function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout | null = null
  return (...args: Parameters<T>) => {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      func(...args)
    }, wait)
  }
}

export const useRouletteSound = () => {
  const soundContext = useSound()

  useEffect(() => {
    if (soundContext && typeof soundContext.loadSound === 'function') {
      Object.entries(GAME_SOUNDS).forEach(([key, path]) => {
        soundContext.loadSound(key, path)
      })
    } else {
      console.warn('DEBUG Cannot load sounds: Sound context not available in RouletteScene')
    }

    // Unload the sounds when the component unmounts
    return () => {
      if (soundContext && typeof soundContext.unloadSound === 'function') {
        Object.keys(GAME_SOUNDS).forEach(key => {
          soundContext.unloadSound(key)
        })
      }
    }
  }, [soundContext])

  const playGameSound = useCallback(
    debounce(
      (soundName: keyof typeof GAME_SOUNDS | 'random-coin', volume = 1, pitch = 1) => {
        if (soundContext && typeof soundContext.playSound === 'function') {
          if (soundName === 'random-coin') {
            // Randomly select one of the coin sounds
            const coinNumber = Math.floor(Math.random() * 3) + 1
            soundContext.playSound(`coin${coinNumber}` as keyof typeof GAME_SOUNDS, volume, pitch)
          } else {
            soundContext.playSound(soundName, volume, pitch)
          }
        } else {
          console.warn('DEBUG Cannot play sound: Sound context not available in RouletteScene')
        }
      },
      5 // debounce wait time in milliseconds
    ),
    [soundContext]
  )

  return { playGameSound }
}
