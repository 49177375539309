import { MotionConfig, type Transition } from 'framer-motion'
import { Canvas, extend } from '@react-three/fiber'
import { MotionCanvas } from 'framer-motion-3d'
import { CoinScene } from './CoinScene'
import { AppGameName } from '@/chains/types'
import { DeltaNumbersDisplay } from '@/components/DeltaNumbersDisplay'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import { SBorderAnimator } from '../shared/BorderFlash'
import { SVolumeSliderWrapper, VolumeSlider } from '@/components/shared/SoundSystem/VolumeSlider'
import { useSound } from '@/components/shared/SoundSystem/SoundContext'
import BeepAudio from '@/assets/audio/mouse-over-slider.wav'
import LoseAudio from '@/assets/audio/Error 07.wav'
import SingleCoinAudio from '@/assets/audio/coin-flip.wav'
import MultiCoinsAudio from '@/assets/audio/coins/Coins 03.wav'
import { useCanvasResize } from '@/hooks/useCanvasResize'
import { ResizingWrapper, SCanvas } from '../styles'
import { type ICanvas } from '../shared/mountedInterface'
import { LoadingChip } from '@/components/shared/LoadingChip'
import ShareWinningsIconButton from '@/components/shared/ShareWinningsIconButton'
// import CoinModeSelectClickAudio from '@/assets/audio/mouse-over-slider.wav'
// import CoinModeSelectSingleAudio from '@/assets/audio/coin-flip.wav'
// import CoinModeSelectMultiAudio from '@/assets/audio/coins/coins 03.wav'

// TODO: Check if this is needed or not
extend({ MotionCanvas })

const defaultTransition: Transition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.35,
}

const GAME_SOUNDS = {
  win: BeepAudio,
  lose: LoseAudio,
  startSingle: SingleCoinAudio,
  startMulti: MultiCoinsAudio,
  coinModeSelectClick: BeepAudio,
  coinModeSelectSingle: SingleCoinAudio,
  coinModeSelectMulti: MultiCoinsAudio,
}

export const CoinFlipCanvas = ({ isMounted, setIsMounted }: ICanvas) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const { canvasSize, isResizing } = useCanvasResize(containerRef, canvasRef)
  const soundContext = useSound()

  useEffect(() => {
    if (soundContext && typeof soundContext.loadSound === 'function') {
      // console.log('DEBUG Loading game sounds in CoinFlipCanvas')
      Object.entries(GAME_SOUNDS).forEach(([key, path]) => {
        soundContext.loadSound(key, path)
      })
    } else {
      console.warn('DEBUG Cannot load sounds: Sound context not available in CoinFlipCanvas')
    }

    // Cleanup function to unload the sounds when the component unmounts
    return () => {
      if (soundContext && typeof soundContext.unloadSound === 'function') {
        // console.log('DEBUG Unloading game sounds in CoinFlipCanvas')
        Object.keys(GAME_SOUNDS).forEach(key => {
          soundContext.unloadSound(key)
        })
      }
    }
  }, [soundContext])

  const playGameSound = useCallback(
    (soundName: string, volume = 1, pitch = 1) => {
      if (soundContext && typeof soundContext.playSound === 'function') {
        console.log(`DEBUG Attempting to play sound: ${soundName} with volume: ${volume}`)
        soundContext.playSound(soundName, volume, pitch)
      } else {
        console.warn('DEBUG Cannot play sound: Sound context not available in CoinFlipCanvas')
      }
    },
    [soundContext]
  )

  const isShowingOutcome = useGameOutcomeStore(state => state.isShowingOutcome)
  const didPlayerWin = useGameOutcomeStore(state => state.didPlayerWin)
  const intensity = useGameOutcomeStore(state => state.intensity)

  return (
    <SCanvas ref={containerRef} style={{ opacity: isMounted ? 1 : 0 }}>
      <SBorderAnimator active={isShowingOutcome} didWin={didPlayerWin} intensity={intensity}>
        <DeltaNumbersDisplay gameName={AppGameName.CoinFlip} />
        <MotionConfig transition={defaultTransition}>
          <MotionCanvas
            ref={canvasRef}
            key={`${canvasSize.width}-${canvasSize.height}`}
            onCreated={_state => {
              setTimeout(() => {
                setIsMounted(true)
              }, 600)
            }}
            gl={{
              antialias: true,
              alpha: true,
              stencil: false,
              depth: true,
            }}
            dpr={[1, 2]}
          >
            <fog attach='fog' args={[0x000000, 5, 65]} />
            <CoinScene playGameSound={playGameSound} />
          </MotionCanvas>
          <Canvas style={{ display: 'none' }}>
            <group />
          </Canvas>
          <SVolumeSliderWrapper>
            <VolumeSlider />
          </SVolumeSliderWrapper>
          {didPlayerWin && <ShareWinningsIconButton />}
        </MotionConfig>
      </SBorderAnimator>
      {isResizing && (
        <ResizingWrapper>
          <LoadingChip width={56} height={56} />
        </ResizingWrapper>
      )}
    </SCanvas>
  )
}
