import { SelectWalletModal } from '@/components/Privy/SelectWalletModal'
import { MobileNoticeBlocker } from '@/components/Mobile/MobileNoticeBlocker'
import { FaucetModal } from '@/components/Testnet/FaucetModal'
import { BuyUsdcModal } from '../shared/Moonpay/BuyUSDC'
import GeofenceModal from '../Modals/GeofenceModal'
import SupportModal from '../Modals/SupportModal'
import { WithdrawPrivyModal } from '../Modals/WithdrawPrivyModal'
import FundWalletModal from '../Modals/FundWalletModal'

export const SingletonModals = () => {
  return (
    <>
      <SelectWalletModal />
      <MobileNoticeBlocker />
      <FaucetModal />
      <GeofenceModal />
      <WithdrawPrivyModal />
      <SupportModal />
      <BuyUsdcModal />
      <FundWalletModal />
    </>
  )
}
