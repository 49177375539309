import { fsocket } from '@/lib/fsocket'
import { useAuthedActiveWallet } from '@/lib/privy/hooks'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { useToken } from '@privy-io/react-auth'

const isLocal = import.meta.env.VITE_DEPLOYMENT_ENV === 'local'

export const MountFSocket = () => {
  const { websocketUrl } = useAppChainConfigStore.use.appChainConfig()
  const { walletAddress, walletReady, sessionVerifyState } =
    useAuthedActiveWallet()
  const { getAccessToken } = useToken()

  const connectToSocket = async (wsUrl: string) => {
    if (isLocal) {
      const authToken = (await getAccessToken()) as string
      fsocket.reconnectAll({ wsUrl, authToken })
    } else {
      fsocket.reconnectAll({ wsUrl })
    }
  }

  useEffect(() => {
    if (walletReady && sessionVerifyState !== 'pending') {
      connectToSocket(websocketUrl)
    }
  }, [walletAddress, websocketUrl, walletReady, sessionVerifyState])

  return null
}
