import { appChainIdMap, DEFAULT_APP_CHAIN_ID } from '@/chains'
import { type SupportedAppChainId } from '@/chains/types'
import { LOCAL_STORAGE } from '@/constants/storage'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { type ConnectedWallet, usePrivy, useWallets } from '@privy-io/react-auth'
import { useSmartWallets } from '@privy-io/react-auth/smart-wallets'

export interface ISmartConnectedWallet extends ConnectedWallet {
  smartWalletAddress?: string
  privyWalletAddress?: string
}

export const useAuthedActiveWallet = () => {
  const { ready, authenticated } = usePrivy()
  const { wallets: unlinkedWallets } = useWallets()
  const wallets = useMemo(() => unlinkedWallets.filter(wallet => wallet.linked), [unlinkedWallets])
  const { client: smartWalletClient } = useSmartWallets()
  const {
    appWalletClientType,
    sessionVerifyState,
    hasSetupSmartWallet,
    isUsingSmartWallet,
    setIsUsingSmartWallet,
  } = useAppChainConfigStore(state => ({
    appWalletClientType: state.appWalletClientType,
    sessionVerifyState: state.sessionVerifyState,
    hasSetupSmartWallet: state.hasSetupSmartWallet,
    isUsingSmartWallet: state.isUsingSmartWallet,
    setIsUsingSmartWallet: state.setIsUsingSmartWallet,
  }))

  const privyWallet = useMemo(() => {
    if (!ready && !authenticated) return null

    return wallets.find(wallet => wallet.connectorType === 'embedded')
  }, [wallets, ready, authenticated])

  const externalWallet = useMemo((): ConnectedWallet | null => {
    if (!ready && !authenticated) return null

    const wallet = wallets.filter(wallet => wallet.connectorType !== 'embedded')
    if (!wallet[0]?.linked) return null

    return wallet[0] || null
  }, [wallets])

  const activeWallet = useMemo(() => {
    let selectedWallet =
      isUsingSmartWallet ?
        wallets.find(wallet => wallet.connectorType === 'embedded' && wallet.linked)
        : wallets.find(wallet => wallet.connectorType !== 'embedded' && wallet.linked)

    if (!selectedWallet)
      selectedWallet = wallets
        .filter(wallet => wallet.connectorType !== 'embedded')
        .find(wallet => wallet.linked)

    let wallet: ISmartConnectedWallet | null | undefined =
      ready && authenticated ? selectedWallet || wallets[0] : null

    wallet = isUsingSmartWallet ? privyWallet : wallet

    if (wallet && smartWalletClient) {
      wallet.smartWalletAddress = smartWalletClient.account.address
      if (isUsingSmartWallet) {
        wallet.privyWalletAddress = wallet.address
        wallet.address = smartWalletClient.account.address
      }
    }

    return wallet
  }, [wallets, ready, authenticated, appWalletClientType, smartWalletClient, isUsingSmartWallet])

  const walletChainId = useMemo(
    () => (activeWallet ? Number(activeWallet.chainId.split(':')[1]) : 0),
    [activeWallet]
  )

  const isWalletLinked = useMemo(() => Boolean(activeWallet?.linked), [activeWallet])

  const isWalletAuthed = useMemo(
    () => Boolean(activeWallet) && ready && authenticated && isWalletLinked,
    [ready, authenticated, isWalletLinked, activeWallet]
  )

  const walletAddress = useMemo(() => {
    if (!isWalletAuthed) return ''

    if (smartWalletClient && isUsingSmartWallet) return smartWalletClient?.account.address

    return activeWallet?.address
  }, [activeWallet, smartWalletClient, isWalletAuthed, isUsingSmartWallet])
  const smartWalletAddress = smartWalletClient?.account.address

  return {
    privyWallet,
    smartWalletClient,
    activeWallet: isWalletAuthed ? activeWallet : null,
    walletAddress: isUsingSmartWallet ? smartWalletAddress : walletAddress,
    smartWalletAddress,
    hasSetupSmartWallet,
    walletChainId,
    isWalletAuthed,
    sessionVerifyState,
    walletReady: ready,
    authenticated: authenticated,
    externalWallet,
  }
}

export const usePrivyTwitterData = () => {
  const { user } = usePrivy()

  return useMemo(() => {
    return user?.twitter
  }, [user?.twitter])
}

export const useActiveWallet = () => {
  const { ready, authenticated, user, isModalOpen } = usePrivy()
  const { wallets: unlinkedWallets } = useWallets()
  const wallets = useMemo(() => unlinkedWallets.filter(wallet => wallet.linked), [unlinkedWallets])
  const { client: smartWalletClient } = useSmartWallets()
  const {
    appProvider: walletProvider,
    appWalletClientType,
    isWrongNetwork,
    sessionVerifyState,
    hasSetupSmartWallet,
    isUsingSmartWallet,
    setIsUsingSmartWallet,
  } = useAppChainConfigStore(state => ({
    appProvider: state.appProvider,
    appWalletClientType: state.appWalletClientType,
    isWrongNetwork: state.isWrongNetwork,
    sessionVerifyState: state.sessionVerifyState,
    hasSetupSmartWallet: state.hasSetupSmartWallet,
    isUsingSmartWallet: state.isUsingSmartWallet,
    setIsUsingSmartWallet: state.setIsUsingSmartWallet,
  }))
  console.log(
    wallets,
    isUsingSmartWallet,
    smartWalletClient,
    smartWalletClient?.account.address,
    wallets.filter(wallet => wallet.connectorType !== 'embedded'),
    'useActiveWallet'
  )

  const privyWallet = useMemo(() => {
    if (!ready && !authenticated) return null

    return wallets.find(wallet => wallet.connectorType === 'embedded')
  }, [wallets, ready, authenticated])

  const externalWallet = useMemo((): ConnectedWallet | null => {
    if (!ready && !authenticated) return null

    const wallet = wallets.filter(wallet => wallet.connectorType !== 'embedded')
    if (!wallet[0]?.linked) return null

    return wallet[0] || null
  }, [wallets])

  const activeWallet = useMemo(() => {
    let selectedWallet =
      isUsingSmartWallet ?
        wallets.find(wallet => wallet.connectorType === 'embedded' && wallet.linked)
        : wallets.find(wallet => wallet.connectorType !== 'embedded' && wallet.linked)

    if (!selectedWallet)
      selectedWallet = wallets
        .filter(wallet => wallet.connectorType !== 'embedded')
        .find(wallet => wallet.linked)

    let wallet: ISmartConnectedWallet | null | undefined =
      ready && authenticated ? selectedWallet || wallets[0] : null

    wallet = isUsingSmartWallet ? privyWallet : wallet

    if (wallet && smartWalletClient) {
      wallet.smartWalletAddress = smartWalletClient.account.address
      if (isUsingSmartWallet) {
        wallet.privyWalletAddress = wallet.address
        wallet.address = smartWalletClient.account.address
      }
    }

    return wallet
  }, [wallets, ready, authenticated, appWalletClientType, smartWalletClient, isUsingSmartWallet])

  const walletChainId = useMemo(
    () => (activeWallet ? Number(activeWallet.chainId.split(':')[1]) : 0),
    [activeWallet]
  )

  const appChainConfig = useMemo(
    () =>
      appChainIdMap[walletChainId as SupportedAppChainId] || appChainIdMap[DEFAULT_APP_CHAIN_ID],
    [walletChainId]
  )

  const isWalletLinked = useMemo(() => Boolean(activeWallet?.linked), [activeWallet])

  const isWalletAuthed = useMemo(
    () => Boolean(activeWallet) && ready && authenticated && isWalletLinked,
    [ready, authenticated, isWalletLinked, activeWallet]
  )

  const walletAddress = useMemo(() => {
    if (!isWalletAuthed) return ''

    if (smartWalletClient && isUsingSmartWallet) return smartWalletClient?.account.address || ''

    return activeWallet?.address || ''
  }, [activeWallet, smartWalletClient, isWalletAuthed, isUsingSmartWallet])
  const smartWalletAddress = smartWalletClient?.account.address || ''

  return {
    privyWallet,
    smartWalletClient,
    activeWallet: isWalletAuthed ? activeWallet : null,
    walletAddress: isUsingSmartWallet ? smartWalletAddress : walletAddress,
    smartWalletAddress,
    hasSetupSmartWallet,
    user,
    isWalletAuthed,
    isModalOpen,
    ready,
    authenticated,
    isWalletLinked,
    walletChainId,
    walletProvider,
    networkStyle: appChainConfig.networkStyle,
    appChainConfig,
    isWrongNetwork,
    sessionVerifyState,
    externalWallet,
  }
}
