import { motion } from 'framer-motion'
import { Button } from '../shared/Button'
import { deviceBP } from '@/design/breakpoints'
import { TEXT_COLORS } from '@/design'
import { phoneBP } from '@/design/phoneSizes'

export const Container = styled.div<{ $maxHeight?: string }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 680px;

  @media (max-width: 760px) {
    min-height: 400px;
    max-height: ${props => props.$maxHeight || '650px'};
  }

  @media ${phoneBP.xs} and (max-height: 605px) {
    height: 500px;
  }

  @media (max-height: 776px) {
    min-height: 620px;
  }
`

export const ContentWrapper = styled.div`
  flex: 1;
`

export const FundPageButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const FundPageButton = styled(Button)`
  background-color: #7d00ff;
  border-radius: 624.9375rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  height: 56px;

  &:hover {
    background-color: #6400cc;
  }
`

export const ButtonText = styled.span`
  flex: 1;
  text-align: center;
  text-transform: none;
  font-size: 1.0625rem;
  font-weight: 600;
  font-family: system-ui, sans-serif;
`

export const SectionContainer = styled(motion.div)`
  text-align: left;
  overflow: hidden;
  background-color: #2c2c2e;
  border-radius: 0.75rem;
  margin-bottom: 16px;
  padding: 1rem;
  text-align: justify;
  cursor: pointer;

  @media ${deviceBP.sm} {
    padding: 0.5rem;
  }
`

export const SectionTitle = styled.h1`
  display: inline-flex;
  align-items: center;

  > h1 {
    display: flex;
    font-size: 20px;
    margin-block: 0;
    font-family: system-ui, sans-serif;

    @media ${deviceBP.sm} {
      font-size: 18px;
    }
  }

  > div {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
  }
`

export const SectionDescription = styled.p`
  display: inline-flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  color: ${TEXT_COLORS.two};
  font-family: system-ui, sans-serif;
  margin-block: 0 10px;
  height: 2.5rem;
`

export const WithdrawalButton = styled(Button)`
  display: flex;
  font-family: system-ui, sans-serif;
  justify-content: flex-end;
  border: none;
  border-radius: 10rem;
  padding: 0.5rem;
  padding-inline: 1rem;
  height: 40px;
  background-color: rgba(118, 118, 128, 0.18);

  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  -moz-backdrop-filter: blur(0px);
  -ms-backdrop-filter: blur(0px);

  &:hover {
    border: none;
    background-color: rgba(118, 118, 128, 0.24);
  }

  img {
    margin-left: 0.5rem;
    width: 12px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
`
