import { type IUISingleRouletteSide } from '@/lib/crypto/roulette'
import { create } from 'zustand'

export interface RouletteEntry {
  type: BetType
  value: number | number[] | string // string for redBlack, oddEven values
  amount: number // Amount of money placed on the bet.
}
export interface RouletteEntryHelperForQK extends RouletteEntry {
  numbers: number[]
}

export type BetType =
  | 'straight'
  | 'split'
  | 'street'
  | 'corner'
  | 'line'
  | 'column'
  | 'dozen'
  | 'redBlack'
  | 'oddEven'
  | 'highLow'
  | 'trio'

export const validBetTypes: BetType[] = [
  'straight',
  'split',
  'street',
  'corner',
  'line',
  'column',
  'dozen',
  'redBlack',
  'oddEven',
  'highLow',
  'trio',
]

export enum RouletteChip {
  ONE_X = 1,
  FIVE_X = 5,
  TWENTY_FIVE_X = 25,
  HUNDRED_X = 100,
}

export interface RouletteGameState {
  gameState: 'IDLE' | 'PLAYING' | 'RESULT_SHOWN' | 'RESETTING'
  minBetAmount: number
  currentEntries: RouletteEntryHelperForQK[]
  selectedChip: RouletteChip | null
  backendResult: any
  totalBetAmount: number
  result?: number
  isSpinning: boolean
  canShowResult: boolean
  isDrawerOpen: boolean
  isKeepingSelection: boolean
  previousEntries: RouletteEntryHelperForQK[]
  uiRouletteSide: IUISingleRouletteSide[]
}

interface RouletteGameActions {
  addEntry: (entry: RouletteEntryHelperForQK) => void
  removeEntry: (index: number) => void
  startRound: () => void
  endRound: () => void
  resetGame: () => void
  setMinBetAmount: (value: number) => void
  setSelectedChip: (value: RouletteChip | null) => void
  resolveEntry: (payload: {
    resultSides: any[]
    playedCount: number
    deltaAmounts: number[]
    totalDeltaAmount: number
  }) => void
  setResult: (result: number) => void
  stopSpinning: () => void
  generateResult: () => number
  setDrawerOpen: (isOpen: boolean) => void
  toggleKeepSelection: () => void
  alertBallStartedAnimation: () => void
}

export const useRouletteGameStore = create<RouletteGameState & RouletteGameActions>((set, get) => ({
  gameState: 'IDLE',
  minBetAmount: 1,
  currentEntries: [],
  selectedChip: RouletteChip.ONE_X,
  backendResult: null,
  totalBetAmount: 0,
  result: undefined,
  isSpinning: true,
  canShowResult: false,
  isDrawerOpen: true,
  isKeepingSelection: true,
  previousEntries: [],
  uiRouletteSide: [],

  addEntry: (entry: RouletteEntryHelperForQK) => {
    const currEntries = get().currentEntries
    const entryKey = `${entry.type}-${Array.isArray(entry.value) ? entry.value.join('-') : entry.value}`
    const roundedAmount = Math.round(entry.amount * 100) / 100

    const existingEntryIndex = currEntries.findIndex(e => {
      const eKey = `${e.type}-${Array.isArray(e.value) ? e.value.join('-') : e.value}`
      return eKey === entryKey
    })

    let newEntries: RouletteEntryHelperForQK[]
    if (existingEntryIndex >= 0) {
      newEntries = [...currEntries]
      const updatedEntry = {
        ...newEntries[existingEntryIndex],
        amount: Math.round((newEntries[existingEntryIndex].amount + roundedAmount) * 100) / 100,
      }
      newEntries.splice(existingEntryIndex, 1)
      newEntries = [updatedEntry, ...newEntries]
    } else {
      newEntries = [{ ...entry, amount: roundedAmount }, ...currEntries]
    }
    set({
      currentEntries: newEntries,
      totalBetAmount: Math.round(newEntries.reduce((sum, e) => sum + e.amount, 0) * 100) / 100,
      uiRouletteSide: newEntries.map(entry => ({
        type: entry.type,
        numbers: entry.numbers,
        amount: entry.amount,
      })),
    })
  },

  generateResult: () => {
    const newResult = Math.floor(Math.random() * 37)
    // console.log('debug generateResult', newResult)
    return newResult
  },

  startRound: () => {
    set({
      gameState: 'PLAYING',
      isSpinning: true,
      canShowResult: false,
      isDrawerOpen: false,
    })

    setTimeout(() => {
      set(state => {
        if (state.gameState === 'PLAYING') {
          return { canShowResult: true }
        }
        return state
      })
    }, 1000)
  },

  alertBallStartedAnimation: () => {
    setTimeout(() => {
      set(state => {
        if (state.gameState === 'PLAYING') {
          return {
            gameState: 'RESULT_SHOWN',
            canShowResult: true,
          }
        }
        return state
      })

      setTimeout(() => {
        set(state => {
          if (state.gameState === 'RESULT_SHOWN') {
            return { gameState: 'RESETTING' }
          }
          return state
        })

        setTimeout(() => {
          const currentState = get().gameState
          if (currentState === 'RESETTING') {
            get().resetGame()
          }
        }, 500)
      }, 2000)
    }, 8000)
  },

  stopSpinning: () => {
    set({ isSpinning: false })
  },

  endRound: () => {
    set(state => {
      if (state.gameState === 'PLAYING') {
        return {
          gameState: 'RESULT_SHOWN',
          canShowResult: false,
        }
      }
      return state
    })

    setTimeout(() => {
      set(state => {
        if (state.gameState === 'RESULT_SHOWN') {
          return { gameState: 'RESETTING' }
        }
        return state
      })

      setTimeout(() => {
        const currentState = get().gameState
        if (currentState === 'RESETTING') {
          get().resetGame()
        }
      }, 4000)
    }, 11000)
  },

  resetGame: () => {
    const currentChip = get().selectedChip
    const currentEntries = get().currentEntries
    const isKeepingSelection = get().isKeepingSelection

    set({
      gameState: 'IDLE',
      currentEntries: isKeepingSelection ? currentEntries : [],
      previousEntries: currentEntries,
      result: undefined,
      backendResult: null,
      totalBetAmount: isKeepingSelection ? get().totalBetAmount : 0,
      isSpinning: true,
      canShowResult: false,
      selectedChip: currentChip,
      isDrawerOpen: true, // @TODO: Maybe dont do this
    })
  },

  setMinBetAmount: (value: number) => {
    set({
      minBetAmount: value,
    })
    get().setSelectedChip(null)
  },

  setSelectedChip: (value: RouletteChip | null) => {
    set({
      selectedChip: value,
      isDrawerOpen: true, // Open drawer when chip is selected
    })
  },

  resolveEntry: (payload: {
    resultSides: any[]
    playedCount: number
    deltaAmounts: number[]
    totalDeltaAmount: number
  }) => {
    set({
      backendResult: {
        resultSides: payload.resultSides,
        playedCount: payload.playedCount,
        deltaAmounts: payload.deltaAmounts,
        totalDeltaAmount: payload.totalDeltaAmount,
      },
      result: payload.resultSides[0],
    })
    get().startRound()
  },

  removeEntry: (index: number) => {
    set(state => {
      const newEntries = [...state.currentEntries]
      newEntries.splice(index, 1)
      return {
        currentEntries: newEntries,
        totalBetAmount: Math.round(newEntries.reduce((sum, e) => sum + e.amount, 0) * 100) / 100,
        uiRouletteSide: newEntries.map(entry => ({
          type: entry.type,
          numbers: entry.numbers,
          amount: entry.amount,
        })),
      }
    })
  },
  setResult: (result: number) => set({ result }),

  setDrawerOpen: (isOpen: boolean) => set({ isDrawerOpen: isOpen }),
  toggleKeepSelection: () => set(state => ({ isKeepingSelection: !state.isKeepingSelection })),
}))
