import React from 'react'
import { Canvas } from '@react-three/fiber'
import { styled } from 'styled-components'
import { BORDER_COLORS, BREAKPOINTS, COMPONENTS } from '@/design'
import { CrashScene } from './CrashScene'
import { SBorderAnimator } from '@/components/Canvases/shared/BorderFlash'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import { SVolumeSliderWrapper, VolumeSlider } from '@/components/shared/SoundSystem/VolumeSlider'
import { GameTimeProvider } from './GameTimeContext'
import { DeltaNumbersDisplay } from '@/components/DeltaNumbersDisplay'
import { AppGameName } from '@/chains/types'
import ShareWinningsIconButton from '@/components/shared/ShareWinningsIconButton'

const SCrashCanvas = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid ${BORDER_COLORS.one};
  backdrop-filter: blur(2px);
  grid-column: 1 / span 5;
  position: relative;
  overflow: hidden;
  -webkit-backdrop-filter: blur(2px);
  -moz-backdrop-filter: blur(2px);
  -ms-backdrop-filter: blur(2px);

  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    height: ${COMPONENTS.mobileGameView}px;
    backdrop-filter: blur(2px);

    /* Vendor prefixes */
    -webkit-backdrop-filter: blur(2px); /* Safari and Chrome */
    -moz-backdrop-filter: blur(2px); /* Firefox */
    -ms-backdrop-filter: blur(2px); /* Edge */
  }

  @media (max-width: ${BREAKPOINTS.sm}px) and (orientation: landscape) {
    height: 99%;
  }
`

export const CrashThree: React.FC = () => {
  const isShowingOutcome = useGameOutcomeStore(state => state.isShowingOutcome)
  const didPlayerWin = useGameOutcomeStore(state => state.didPlayerWin)
  const intensity = useGameOutcomeStore(state => state.intensity)

  return (
    <SCrashCanvas>
      <DeltaNumbersDisplay gameName={AppGameName.Crash} />
      <SBorderAnimator active={isShowingOutcome} didWin={didPlayerWin} intensity={intensity}>
        <GameTimeProvider>
          {/* <TimeDebugOverlay /> */}
          <Canvas
            camera={{ position: [0, 0, 20], fov: 60 }}
            // camera={{ position: [0, 0, 20], fov: 120 }}
            shadows
            dpr={[1, 2]}
            gl={{ antialias: true, stencil: false, preserveDrawingBuffer: false, alpha: true }}
          >
            <fog attach='fog' args={['#000000', 5, 80]} />
            <CrashScene />
          </Canvas>
          <SVolumeSliderWrapper>
            <VolumeSlider />
          </SVolumeSliderWrapper>
          {didPlayerWin && <ShareWinningsIconButton />}
        </GameTimeProvider>
      </SBorderAnimator>
    </SCrashCanvas>
  )
}
export default CrashThree
