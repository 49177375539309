export enum LOCAL_STORAGE {
  AUTH_TOKEN = 'auth-token',
  HAS_CONNECTED_PREV = 'has-connected-prev',
  HAS_RESET_RANDOMNESS_TYPE = 'has-reset-randomness-type',
  TERMS_CONDITIONS = 'terms-conditions',
  RANDOMNESS_TYPE = 'randomness-type',
  IS_USING_QUICKPLAY = 'is-using-quickplay',
  ZERO_DEV_SESSION_KEY = 'zero-dev-session-key',
  TRIALS = 'trials',
  TX_HASH_TO_TRIAL_DATA = 'tx-hash-to-trial-data',
  TRIAL_ID_TO_TX_HASH = 'trial-id-to-tx-hash',
  MAX_VALUES = 'max-values',
  GAME_NAME = 'game-name',
  SELECTED_CHAIN_NAME = 'selected-chain-name',
  SELECTED_APP_CHAIN_ID = 'selected-app-chain-id',
  SELECTED_APP_WALLET_CLIENT_TYPE = 'selected-app-wallet-client-type',
  IS_USING_SMART_WALLET = 'IS_USING_SMART_WALLET',
}

export enum SESSION_STORAGE {
  HAS_CLICKED_NOTI_PILL_KEY = 'has-clicked-noti-pill',
  HAS_CLICKED_FUND_WALLET = 'has-clicked-fund-wallet',
}
