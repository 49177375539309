import { OUTCOME_COLORS } from '@/design/colors'
import { styled, css, keyframes } from 'styled-components'

// colors
const lossColor = OUTCOME_COLORS.loss
const winColor1 = OUTCOME_COLORS.win.one
const winColor2 = OUTCOME_COLORS.win.two
const winColor3 = OUTCOME_COLORS.win.three
const winColor4 = OUTCOME_COLORS.win.four
const winColor5 = OUTCOME_COLORS.win.five

// Keyframe animations for different intensities
const glowWin1 = keyframes`
  0% { box-shadow: inset 0 0 5px ${winColor1}; }
  50% { box-shadow: inset 0 0 6px ${winColor1}; }
  100% { box-shadow: inset 0 0 5px ${winColor1}; }
`

const glowWin2 = keyframes`
  0% { box-shadow: inset 0 0 6px ${winColor2}; }
  50% { box-shadow: inset 0 0 7px ${winColor2}; }
  100% { box-shadow: inset 0 0 6px ${winColor2}; }
`

const glowWin3 = keyframes`
  0% { box-shadow: inset 0 0 8px ${winColor3}; }
  50% { box-shadow: inset 0 0 11px ${winColor3}; }
  100% { box-shadow: inset 0 0 8px ${winColor3}; }
`

const glowWin4 = keyframes`
  0% { box-shadow: inset 0 0 10px ${winColor4}; }
  50% { box-shadow: inset 0 0 13px ${winColor4}; }
  100% { box-shadow: inset 0 0 10px ${winColor4}; }
`

const glowWin5 = keyframes`
  0% { box-shadow: inset 0 0 15px ${winColor5}; }
  50% { box-shadow: inset 0 0 25px ${winColor5}; }
  100% { box-shadow: inset 0 0 15px ${winColor5}; }
`

const glowLose = keyframes`
  /* original color: rgb(255, 26, 26) */
  0% { box-shadow: inset 0 0 8px ${lossColor};  }
  50% { box-shadow: inset 0 0 11px ${lossColor};  }
  100% { box-shadow: inset 0 0 8px ${lossColor};  }
`

// Conditional styling based on intensity
const styleBorder = (active: boolean, didWin: boolean, intensity: number) => {
  if (active) {
    if (didWin) {
      switch (intensity) {
        case 1:
          return css`
            animation: ${glowWin1} 1.5s infinite alternate;
          `
        case 2:
          return css`
            animation: ${glowWin2} 1.5s infinite alternate;
          `
        case 3:
          return css`
            animation: ${glowWin3} 1.5s infinite alternate;
          `
        case 4:
          return css`
            animation: ${glowWin4} 1.5s infinite alternate;
          `
        case 5:
          return css`
            animation: ${glowWin5} 1s infinite alternate;
          `
        default:
          return css`
            animation: ${glowWin1} 1.5s infinite alternate;
          `
      }
    } else {
      return css`
        border: none;
        /* animation: ${glowLose} 1.5s infinite alternate; */
      `
    }
  } else {
    return 'none'
  }
}

export const SBorderAnimator = styled.div<{ active: boolean; didWin: boolean; intensity: number }>`
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  ${({ active, didWin, intensity }) => styleBorder(active, didWin, intensity)}
  border-color: red;
  border-width: 5px;
  z-index: 10;
  /* overflow: hidden; */
`

// export const SBorderAnimatorBackground = styled.div`
//   position: relative;
//   width: 100%;
//   height: 100%;
//   border-radius: 6px;
//   overflow: hidden;

//   &::after {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     border: 30px solid black;
//     border-radius: 6px;
//     pointer-events: none;
//     z-index: 10;
//   }
// `
