import React, { useState, useEffect } from 'react'
import { styled } from 'styled-components'
import { type ChipPosition, type BetPosition } from './types'
import { type BetType } from '@/store/useRouletteGameStore'
import { numberGrid, redNumbers } from './constants'
import { Chip } from './Chip'
import { motion, AnimatePresence } from 'framer-motion'

const OverlayContainer = styled.div`
  position: absolute;
  inset: 0;
  pointer-events: none;
`

const BetButton = styled.button<{ $debug?: boolean }>`
  position: absolute;
  background: ${props => (props.$debug ? 'rgba(255, 255, 255, 0.1)' : 'transparent')};
  border: ${props => (props.$debug ? '1px solid rgba(255, 255, 255, 0.2)' : 'none')};
  cursor: pointer;
  pointer-events: auto;

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
`

const StraightButton = styled(BetButton)`
  width: calc(100% / 12);
  height: calc(100% / 3);
  bottom: 0;
  margin: 0;
`

const SplitButtonHorizontal = styled(BetButton)`
  width: calc(100% / 12 * 0.2);
  height: calc(100% / 3);
  transform: translateX(-50%);
`

const SplitButtonVertical = styled(BetButton)`
  width: calc(100% / 12);
  height: calc(100% / 3 * 0.2);
  transform: translateY(-50%);
`

const CornerButton = styled(BetButton)`
  width: calc(100% / 12 * 0.2);
  height: calc(100% / 3 * 0.2);
  transform: translate(-50%, -50%);
  z-index: 2;
`

const DozenButton = styled(BetButton)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.$debug ? 'rgba(255, 255, 255, 0.1)' : 'transparent')};
  border: ${props => (props.$debug ? '1px solid rgba(255, 255, 255, 0.2)' : 'none')};
`

const HalfButton = styled(BetButton)`
  width: 100%;
  height: 100%;
  background: ${props => (props.$debug ? 'rgba(255, 255, 255, 0.1)' : 'transparent')};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ColumnButton = styled(BetButton)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.$debug ? 'rgba(255, 255, 255, 0.1)' : 'transparent')};
  border: ${props => (props.$debug ? '1px solid rgba(255, 255, 255, 0.2)' : 'none')};
`

const ZeroButton = styled(BetButton)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.$debug ? 'rgba(255, 255, 255, 0.1)' : 'transparent')};
  border: ${props => (props.$debug ? '1px solid rgba(255, 255, 255, 0.2)' : 'none')};
`

const ChipContainer = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const TrioButton = styled(BetButton)`
  width: calc(100% / 12 * 0.3);
  height: calc(100% / 3 * 0.3);
  transform: translate(-50%, -50%);
`

const StreetButton = styled(BetButton)`
  width: calc(100% / 12 * 0.3);
  height: calc(100% / 3 * 0.3);
  transform: translateX(-50%);
`

const LineButton = styled(BetButton)`
  width: calc(100% / 12 * 0.3);
  height: calc(100% / 3 * 2 * 0.2);
  transform: translateX(-50%);
`

interface BettingOverlayProps {
  onBetPlaced: (position: BetPosition) => void
  placedBets: Map<string, { position: BetPosition; amount: number }>
  hoverAmount: number
  debug?: boolean
  onBetRemoved?: (position: BetPosition) => void
  disabled?: boolean
}

const getBetKey = (position: BetPosition) => {
  if (
    position.value &&
    ['red', 'black', 'odd', 'even', 'high', 'low'].includes(position.value as string)
  ) {
    return `${position.type}-${position.value}`
  }
  return `${position.type}-${position.numbers.join('-')}`
}

export const BettingOverlay: React.FC<BettingOverlayProps> = ({
  onBetPlaced,
  placedBets,
  hoverAmount,
  debug,
  onBetRemoved,
  disabled = false,
}) => {
  const [hoverPosition, setHoverPosition] = useState<BetPosition | null>(null)
  const [removingChip, setRemovingChip] = useState<string | null>(null)

  useEffect(() => {
    if (disabled) {
      setHoverPosition(null)
    }
  }, [disabled])

  const handleBetClick = async (position: BetPosition, event: React.MouseEvent) => {
    if (disabled) return // Early return if disabled

    const betKey = getBetKey(position)

    if (event.shiftKey) {
      if (placedBets.has(betKey)) {
        setRemovingChip(betKey)
        // Wait for animation to complete
        await new Promise(resolve => setTimeout(resolve, 200))
        onBetRemoved?.(position)
        setRemovingChip(null)
      }
      // Do nothing if shift is held and no bet exists
    } else {
      onBetPlaced(position)
    }
    setHoverPosition(null)
  }

  const renderChip = (position: BetPosition, amount: number, isHover = false) => {
    const betKey = getBetKey(position)
    if (isHover && disabled) return null

    return (
      <AnimatePresence mode='wait'>
        {removingChip !== betKey && (
          <ChipContainer
            initial={isHover ? {} : { scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
          >
            <Chip position={{ x: 50, y: 50 } as ChipPosition} amount={amount} isHover={isHover} />
          </ChipContainer>
        )}
      </AnimatePresence>
    )
  }

  const handleMouseEnter = (position: BetPosition) => {
    if (!disabled) {
      setHoverPosition(position)
    }
  }

  return (
    <OverlayContainer>
      {/* Zero bet */}
      {(() => {
        const position: BetPosition = {
          type: 'straight',
          numbers: [0],
        }
        const betKey = getBetKey(position)
        const existingBet = placedBets.get(betKey)

        return (
          <ZeroButton
            key='zero'
            style={{
              left: '-8.33%',
              top: 0,
              width: 'calc(100% / 12)',
              height: '100%',
            }}
            onMouseEnter={() => handleMouseEnter(position)}
            onMouseLeave={() => setHoverPosition(null)}
            onClick={e => handleBetClick(position, e)}
            $debug={debug}
          >
            {existingBet && renderChip(position, existingBet.amount)}
            {hoverPosition &&
              getBetKey(hoverPosition) === betKey &&
              renderChip(position, hoverAmount, true)}
          </ZeroButton>
        )
      })()}

      {/* Dozen bets */}
      {[1, 2, 3].map(dozen => {
        const startNum = (dozen - 1) * 12 + 1
        const endNum = dozen * 12
        const position: BetPosition = {
          type: 'dozen',
          numbers: Array.from({ length: endNum - startNum + 1 }, (_, index) => startNum + index),
        }
        const betKey = getBetKey(position)
        const existingBet = placedBets.get(betKey)

        return (
          <DozenButton
            key={`dozen-${dozen}`}
            style={{
              left: `${(dozen - 1) * (100 / 3)}%`,
              top: '100%',
              width: `${100 / 3}%`,
              height: 'calc(100% / 3)',
            }}
            onMouseEnter={() => handleMouseEnter(position)}
            onMouseLeave={() => setHoverPosition(null)}
            onClick={e => handleBetClick(position, e)}
            $debug={debug}
          >
            {existingBet && renderChip(position, existingBet.amount)}
            {hoverPosition &&
              getBetKey(hoverPosition) === betKey &&
              renderChip(position, hoverAmount, true)}
          </DozenButton>
        )
      })}

      {/* Bottom row bets */}
      {[
        {
          type: 'highLow' as BetType,
          numbers: Array.from({ length: 18 }, (_, i) => i + 1),
          value: 'low',
        },
        {
          type: 'oddEven' as BetType,
          numbers: Array.from({ length: 18 }, (_, i) => (i + 1) * 2),
          value: 'even',
        },
        {
          type: 'redBlack' as BetType,
          numbers: redNumbers,
          value: 'red',
        },
        {
          type: 'redBlack' as BetType,
          numbers: numberGrid.flat().filter(n => !redNumbers.includes(n)),
          value: 'black',
        },
        {
          type: 'oddEven' as BetType,
          numbers: Array.from({ length: 18 }, (_, i) => i * 2 + 1),
          value: 'odd',
        },
        {
          type: 'highLow' as BetType,
          numbers: Array.from({ length: 18 }, (_, i) => i + 19),
          value: 'high',
        },
      ].map((bet, index) => {
        const position: BetPosition = {
          type: bet.type,
          numbers: bet.numbers,
          value: bet.value,
        }
        const betKey = getBetKey(position)
        const existingBet = placedBets.get(betKey)

        return (
          <HalfButton
            key={`half-${index}`}
            style={{
              left: `${index * (100 / 6)}%`,
              top: 'calc(100% + calc(100% / 3))',
              width: `${100 / 6}%`,
              height: 'calc(100% / 3)',
              position: 'absolute',
              zIndex: 1,
            }}
            onMouseEnter={() => handleMouseEnter(position)}
            onMouseLeave={() => setHoverPosition(null)}
            onClick={e => handleBetClick(position, e)}
            $debug={debug}
          >
            {existingBet && renderChip(position, existingBet.amount)}
            {hoverPosition &&
              getBetKey(hoverPosition) === betKey &&
              renderChip(position, hoverAmount, true)}
          </HalfButton>
        )
      })}

      {/* Straight bets */}
      {numberGrid.map((row, rowIndex) =>
        row.map((num, colIndex) => {
          const position: BetPosition = {
            type: 'straight',
            numbers: [num],
          }
          const betKey = getBetKey(position)
          const existingBet = placedBets.get(betKey)

          return (
            <StraightButton
              key={`straight-${num}`}
              style={{
                left: `${colIndex * (100 / 12)}%`,
                top: `${rowIndex * (100 / 3)}%`,
              }}
              onMouseEnter={() => handleMouseEnter(position)}
              onMouseLeave={() => setHoverPosition(null)}
              onClick={e => handleBetClick(position, e)}
              $debug={debug}
            >
              {existingBet && renderChip(position, existingBet.amount)}
              {hoverPosition &&
                getBetKey(hoverPosition) === betKey &&
                renderChip(position, hoverAmount, true)}
            </StraightButton>
          )
        })
      )}

      {/* Horizontal splits */}
      {numberGrid.map((row, rowIndex) =>
        row.slice(0, -1).map((num, colIndex) => {
          const position: BetPosition = {
            type: 'split',
            numbers: [num, numberGrid[rowIndex][colIndex + 1]],
          }
          const betKey = getBetKey(position)
          const existingBet = placedBets.get(betKey)

          return (
            <SplitButtonHorizontal
              key={`split-h-${rowIndex}-${colIndex}`}
              style={{
                left: `${(colIndex + 1) * (100 / 12)}%`,
                top: `${rowIndex * (100 / 3)}%`,
              }}
              onMouseEnter={() => handleMouseEnter(position)}
              onMouseLeave={() => setHoverPosition(null)}
              onClick={e => handleBetClick(position, e)}
              $debug={debug}
            >
              {existingBet && renderChip(position, existingBet.amount)}
              {hoverPosition &&
                getBetKey(hoverPosition) === betKey &&
                renderChip(position, hoverAmount, true)}
            </SplitButtonHorizontal>
          )
        })
      )}

      {/* Vertical splits */}
      {numberGrid.slice(0, -1).map((row, rowIndex) =>
        row.map((num, colIndex) => {
          const position: BetPosition = {
            type: 'split',
            numbers: [num, numberGrid[rowIndex + 1][colIndex]],
          }
          const betKey = getBetKey(position)
          const existingBet = placedBets.get(betKey)

          return (
            <SplitButtonVertical
              key={`split-v-${rowIndex}-${colIndex}`}
              style={{
                left: `${colIndex * (100 / 12)}%`,
                top: `${(rowIndex + 1) * (100 / 3)}%`,
              }}
              onMouseEnter={() => handleMouseEnter(position)}
              onMouseLeave={() => setHoverPosition(null)}
              onClick={e => handleBetClick(position, e)}
              $debug={debug}
            >
              {existingBet && renderChip(position, existingBet.amount)}
              {hoverPosition &&
                getBetKey(hoverPosition) === betKey &&
                renderChip(position, hoverAmount, true)}
            </SplitButtonVertical>
          )
        })
      )}

      {/* Corner bets */}
      {numberGrid.slice(0, -1).map((row, rowIndex) =>
        row.slice(0, -1).map((num, colIndex) => {
          const position: BetPosition = {
            type: 'corner',
            numbers: [
              num,
              numberGrid[rowIndex][colIndex + 1],
              numberGrid[rowIndex + 1][colIndex],
              numberGrid[rowIndex + 1][colIndex + 1],
            ],
          }
          const betKey = getBetKey(position)
          const existingBet = placedBets.get(betKey)

          return (
            <CornerButton
              key={`corner-${rowIndex}-${colIndex}`}
              style={{
                left: `${(colIndex + 1) * (100 / 12)}%`,
                top: `${(rowIndex + 1) * (100 / 3)}%`,
              }}
              onMouseEnter={() => handleMouseEnter(position)}
              onMouseLeave={() => setHoverPosition(null)}
              onClick={e => handleBetClick(position, e)}
              $debug={debug}
            >
              {existingBet && renderChip(position, existingBet.amount)}
              {hoverPosition &&
                getBetKey(hoverPosition) === betKey &&
                renderChip(position, hoverAmount, true)}
            </CornerButton>
          )
        })
      )}

      {/* Column bets (2:1) */}
      {[3, 2, 1].map(column => {
        const startNum = column
        const numberCount = 12
        const position: BetPosition = {
          type: 'column',
          numbers: Array.from({ length: numberCount }, (_, index) => startNum + 3 * index),
        }
        const betKey = getBetKey(position)
        const existingBet = placedBets.get(betKey)

        return (
          <ColumnButton
            key={`column-${column}`}
            style={{
              right: `-${100 / 12}%`,
              top: `${(3 - column) * (100 / 3)}%`,
              width: 'calc(100% / 12)',
              height: 'calc(100% / 3)',
            }}
            onMouseEnter={() => handleMouseEnter(position)}
            onMouseLeave={() => setHoverPosition(null)}
            onClick={e => handleBetClick(position, e)}
            $debug={debug}
          >
            {existingBet && renderChip(position, existingBet.amount)}
            {hoverPosition &&
              getBetKey(hoverPosition) === betKey &&
              renderChip(position, hoverAmount, true)}
          </ColumnButton>
        )
      })}

      {/* Street bets (vertical columns of 3 numbers) */}
      {Array.from({ length: 12 }, (_, colIndex) => {
        // Get numbers from the column and sort them in ascending order
        const numbers = numberGrid.map(row => row[colIndex]).sort((a, b) => a - b)
        const position: BetPosition = {
          type: 'street',
          numbers,
        }
        const betKey = getBetKey(position)
        const existingBet = placedBets.get(betKey)

        return (
          <StreetButton
            key={`street-${colIndex}`}
            style={{
              left: `${colIndex * (100 / 12) + 100 / 12 / 2}%`,
              bottom: 'calc(-1 * (100% / 3 * 0.3 / 2))',
              transform: 'translateX(-50%)',
            }}
            onMouseEnter={() => handleMouseEnter(position)}
            onMouseLeave={() => setHoverPosition(null)}
            onClick={e => handleBetClick(position, e)}
            $debug={debug}
          >
            {existingBet && renderChip(position, existingBet.amount)}
            {hoverPosition &&
              getBetKey(hoverPosition) === betKey &&
              renderChip(position, hoverAmount, true)}
          </StreetButton>
        )
      })}

      {/* Line bets (vertical pairs of columns - 6 numbers) */}
      {Array.from({ length: 11 }, (_, colIndex) => {
        // Get all numbers from both columns
        const firstColumn = numberGrid.map(row => row[colIndex])
        const secondColumn = numberGrid.map(row => row[colIndex + 1])
        // Combine and sort numbers in ascending order
        const numbers = [...firstColumn, ...secondColumn].sort((a, b) => a - b)

        const position: BetPosition = {
          type: 'line',
          numbers,
        }
        const betKey = getBetKey(position)
        const existingBet = placedBets.get(betKey)

        return (
          <LineButton
            key={`line-${colIndex}`}
            style={{
              left: `${(colIndex + 1) * (100 / 12)}%`,
              top: '100%',
              height: 'calc(100% / 3 * 0.3)',
              transform: 'translateX(-50%) translateY(-50%)',
            }}
            onMouseEnter={() => handleMouseEnter(position)}
            onMouseLeave={() => setHoverPosition(null)}
            onClick={e => handleBetClick(position, e)}
            $debug={debug}
          >
            {existingBet && renderChip(position, existingBet.amount)}
            {hoverPosition &&
              getBetKey(hoverPosition) === betKey &&
              renderChip(position, hoverAmount, true)}
          </LineButton>
        )
      })}

      {/* Trio bets (0,2,3 and 0,1,2) */}
      {[
        { numbers: [0, 2, 3], x: 0, y: 33.33 }, // Top trio (0-2-3)
        { numbers: [0, 1, 2], x: 0, y: 66.66 }, // Bottom trio (0-1-2)
      ].map((trio, index) => {
        const position: BetPosition = {
          type: 'trio',
          numbers: trio.numbers,
        }
        const betKey = getBetKey(position)
        const existingBet = placedBets.get(betKey)

        return (
          <TrioButton
            key={`trio-${index}`}
            style={{
              left: '0%',
              top: `${trio.y}%`,
            }}
            onMouseEnter={() => handleMouseEnter(position)}
            onMouseLeave={() => setHoverPosition(null)}
            onClick={e => handleBetClick(position, e)}
            $debug={debug}
          >
            {existingBet && renderChip(position, existingBet.amount)}
            {hoverPosition &&
              getBetKey(hoverPosition) === betKey &&
              renderChip(position, hoverAmount, true)}
          </TrioButton>
        )
      })}
    </OverlayContainer>
  )
}
