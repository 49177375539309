import React, { useEffect, useState } from 'react'
import { motion, type Variants } from 'framer-motion'
import { BORDER_COLORS, BREAKPOINTS } from '@/design'
import { SVGS } from '@/assets'

const CommonAlertStyles = styled(motion.div)`
  position: absolute;
  top: 300px;
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  width: content-fit;
  height: 5%;
  background-color: black;
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 6px;

  @media (min-aspect-ratio: 16/9) and (max-width: ${BREAKPOINTS.sm}px) and (orientation: landscape) {
    top: 50%;
    padding: 6px 4px;
  }
`

const LeftAlert = styled(CommonAlertStyles)`
  left: 1%;
`

const RightAlert = styled(CommonAlertStyles)`
  left: 90.5%;

  @media (min-aspect-ratio: 16/9) and (max-width: ${BREAKPOINTS.sm}px) and (orientation: landscape) {
    left: 93%;
  }
`

const LeftAlertVariant: Variants = {
  hidden: {
    x: -50,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 1,
      duration: 1,
      ease: 'easeInOut',
    },
  },
  exit: {
    x: -50,
    opacity: 0,
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
}

const RightAlertVariant: Variants = {
  hidden: {
    x: 50,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 1,
      duration: 1,
      ease: 'easeInOut',
    },
  },
  exit: {
    x: 50,
    opacity: 0,
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
}

export const SwiperAlert = () => {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <LeftAlert
        variants={LeftAlertVariant}
        initial='hidden'
        animate={visible ? 'visible' : 'exit'}
      >
        <img src={SVGS.caretLeft} />
      </LeftAlert>
      <RightAlert
        variants={RightAlertVariant}
        initial='hidden'
        animate={visible ? 'visible' : 'exit'}
      >
        <img src={SVGS.caretRight} />
      </RightAlert>
    </>
  )
}
