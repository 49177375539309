import { proxy } from 'valtio'

export const geoblockModalState = proxy<{
  isVisible: boolean
  region: string | null
}>({
  isVisible: false,
  region: null,
})
export const useGeoblockModal = () => {
  const showGeoblockModal = (region: string) => {
    geoblockModalState.region = region
    geoblockModalState.isVisible = true
  }

  const hideGeoblockModal = () => {
    geoblockModalState.isVisible = false
  }

  return {
    showGeoblockModal,
    hideGeoblockModal,
  }
}
