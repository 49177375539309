import { createGlobalStyle } from 'styled-components'
import { BACKGROUND_COLORS, FARE_COLORS, TEXT_COLORS } from '@/design/colors'
import { COMPONENTS } from '@/design/components'
import { BREAKPOINTS, SPACING } from '@/design/spacing'
import { FONT_STYLES, fontFacesCSS } from '@/design/fonts'

export const GlobalStyle = createGlobalStyle`
  body {
    ${fontFacesCSS};
    background-color: ${BACKGROUND_COLORS.one};
    color: ${TEXT_COLORS.one};
    margin: 0;
    overflow: hidden;
    
    * {
      font-family: Gohu, monospace;
      ${FONT_STYLES.md};
    }

    /* For Firefox */
    * {
      scrollbar-width: none;
      -ms-overflow-style: none;
    }

    /* For Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      display: none;
    }
  }

  #root {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
  }
`

export const RootWrapper = styled.div`
  display: grid;
  grid-template-rows: ${COMPONENTS.header}px calc(100vh - ${COMPONENTS.header}px);
  background: linear-gradient(0deg, #000000, ${FARE_COLORS.black});
  background-blend-mode: darken;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  @media (min-aspect-ratio: 16/9) and (max-width: ${BREAKPOINTS.sm}px) and (orientation: landscape) {
    display: block;
  }
`

export const AppWrapper = styled.div<{ $isMobileScreen?: boolean }>`
  ${({ $isMobileScreen }) =>
    !$isMobileScreen ?
      css`
        display: grid;
        height: calc(100vh - ${COMPONENTS.header + SPACING.md * 2}px);

        @media only screen and (min-width: ${BREAKPOINTS.lg}px) {
          grid-template-columns: max-content 1fr ${COMPONENTS.rightPanel + SPACING.md}px;
        }

        @media only screen and (max-width: ${BREAKPOINTS.lg}px) {
          grid-template-columns: 0 1fr ${COMPONENTS.rightPanel - 40 + SPACING.md}px;
        }

        @media only screen and (max-width: ${BREAKPOINTS.md}px) {
          grid-template-columns: 0 1fr ${COMPONENTS.rightPanel - 60 + SPACING.md}px;
        }

        > * {
          margin: auto 0;
        }
      `
      : css`
        display: grid;
      `}
`

export const noUserSelect = css`
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
`
