import useMaxValues from '@/hooks/useMaxValues'
import { useAuthedActiveWallet } from '@/lib/privy/hooks'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import useCurrencyStore from '@/store/useCurrencyStore'
import useSUContractStore from '@/store/useSUContractStore'

export const PrivyQuickplaySmartWalletListener = () => {
  const { smartWalletAddress, walletChainId, smartWalletClient } = useAuthedActiveWallet()

  const { appContracts } = useAppChainConfigStore(state => ({
    appContracts: state.appContracts,
  }))

  const { smartWalletAllowance } = useCurrencyStore(state => ({
    smartWalletAllowance: state.allowances.currency,
  }))

  const { setApprovedGameState, setHasApprovedContracts, hasApprovedSmartWalletGameContracts } =
    useSUContractStore(state => ({
      setApprovedGameState: state.setApprovedGameState,
      setHasApprovedContracts: state.setHasApprovedContracts,
      hasApprovedSmartWalletGameContracts: state.hasApprovedSmartWalletGameContracts,
    }))
  const { fetchAndSetSCMaxValues } = useMaxValues()

  const switchSmartWalletChainId = useCallback(
    async (id: number) => {
      try {
        await smartWalletClient?.switchChain({ id })
        console.log('Switched smart wallet chain')
      } catch (err) {
        console.error(err)
      }
    },
    [smartWalletClient]
  )

  const checkApprovedGameContracts = useCallback(async (): Promise<boolean> => {
    if (!appContracts?.bankroll || !appContracts?.vault || !smartWalletAddress) return false
    // setApprovedGameState('pending')
    useSUContractStore.setState({
      hasApprovedSmartWalletGameContracts: 'pending',
    })

    try {
      const isApproved = await appContracts.ws.bankroll.isValidContractForFundOwner(
        appContracts.vault.address,
        smartWalletAddress
      )
      // setApprovedGameState(isApproved ? 'approved' : 'not-approved')
      // setHasApprovedContracts(isApproved)
      fetchAndSetSCMaxValues(smartWalletAddress)

      useSUContractStore.setState({
        hasApprovedSmartWalletGameContracts: isApproved ? 'approved' : 'not-approved',
      })

      return isApproved
    } catch (err) {
      console.error(err)
      // setApprovedGameState('not-approved')
      useSUContractStore.setState({
        hasApprovedSmartWalletGameContracts: 'not-approved',
      })
    }

    return false
  }, [appContracts, fetchAndSetSCMaxValues, smartWalletAddress])

  useEffect(() => {
    useAppChainConfigStore.setState({
      hasSetupSmartWallet: Boolean(
        smartWalletAddress &&
          hasApprovedSmartWalletGameContracts === 'approved' &&
          Number(smartWalletAllowance) > 0
      ),
    })
  }, [hasApprovedSmartWalletGameContracts, smartWalletAllowance, smartWalletAddress])

  useEffect(() => {
    switchSmartWalletChainId(walletChainId)
    checkApprovedGameContracts()
  }, [smartWalletAddress, walletChainId])

  return null
}
