import React, { useEffect } from 'react'
import { styled } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { TEXT_COLORS } from '@/design'
import { useRouletteGameStore } from '@/store/useRouletteGameStore'

const Container = styled(motion.div)`
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  z-index: 100;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ResultText = styled(motion.div)<{ $isWinning: boolean }>`
  font-size: 40px;
  font-weight: 700;
  color: ${props => (props.$isWinning ? '#27ff83' : '#ff2763')};
  text-shadow: 0 0 10px
    ${props => (props.$isWinning ? 'rgba(0, 255, 0, 0.5)' : 'rgba(255, 0, 0, 0.5)')};
  letter-spacing: 2px;
`

interface AnimatedResultTextProps {
  result: number
  isVisible: boolean
}

export const AnimatedResultText: React.FC<AnimatedResultTextProps> = ({ result, isVisible }) => {
  const { currentEntries } = useRouletteGameStore()

  return (
    <AnimatePresence>
      {isVisible && (
        <Container
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0, opacity: 0 }}
          transition={{ duration: 0.2, ease: 'easeOut' }}
        >
          <ResultText $isWinning={currentEntries.some(entry => entry.numbers.includes(result))}>
            {result}
          </ResultText>
        </Container>
      )}
    </AnimatePresence>
  )
}
