import { ButtonEnum } from '@/components/shared/Button'
import ModalCard from '@/components/shared/Modal/Card'
import { type SendTransactionModalUIOptions, usePrivy } from '@privy-io/react-auth'
import { useSnapshot } from 'valtio'
import { Input } from '../../shared/Input'
import { BORDER_COLORS, FARE_COLORS, TEXT_COLORS } from '../../../design'
import useCurrencyStore from '../../../store/useCurrencyStore'
import CountUp from 'react-countup'
import { SVGS } from '../../../assets'
import { WithdrawInput } from './WithdrawInput'
import { useNetworkStyle } from '../../../hooks/useCurrency'
import { addAppNoti } from '../../../store/useNotiStore'
import { usePostLog } from '../../../lib/posthog/logging'
import { useAppChainConfigStore } from '../../../store/useAppChainConfigStore'
import { useQuickplaySmartWallet } from '../../../hooks/useQuickplaySmartWallet'
import { shortenAddress } from '../../../utils/text'
import { createBicoPaymasterClient } from '@biconomy/sdk'
import { encodeFunctionData, parseAbi, parseUnits } from 'viem'
import { useWithdrawPrivyModalState, withdrawPrivyModalState } from './withdrawPrivyModalState'
import {
  FundPageButton,
  WithdrawalButton,
  SectionDescription,
  FundPageButtonWrapper,
  ButtonText,
} from '../styles'

export const SInputWrapper = styled.div`
  width: 100%;
  padding-bottom: 12px;
  margin-bottom: 12px;
`

export const SInput = styled(Input)`
  background: #2c2c2e;
  border: 1px solid ${BORDER_COLORS.one};
  color: ${TEXT_COLORS.one};
  border-radius: 12px;
  padding: 8px 16px;
  font-size: 14px;
  flex: 1;
  height: 40px;
  font-family: system-ui, sans-serif;

  &:focus {
    outline: none;
    border: 1px solid ${FARE_COLORS.aqua};
  }
`

const BalanceContent = styled.div`
  display: flex;
  padding: 8px 0px;

  #input-num-format {
    padding-top: 8px;
    padding-bottom: 12px;
    font-family: system-ui, sans-serif;
  }

  &.balance-content-column {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
`
const WithdrawalContent = styled.div`
  padding-bottom: 64px;
`

export const WithdrawPrivyModal = () => {
  const { exportWallet } = usePrivy()
  const networkStyle = useNetworkStyle()
  const { isVisible } = useSnapshot(withdrawPrivyModalState)
  const { setWithdrawPrivyModal } = useWithdrawPrivyModalState()
  const currencyBalance = Number(useCurrencyStore(state => state.balances.currency))
  const [receiverAddress, setReceiverAddress] = useState('')
  const [withdrawCurrencyAmount, setWithdrawCurrencyAmount] = useState(0)
  const [isSendingTx, setIsSendingTx] = useState(false)
  const { postlog } = usePostLog()
  const { appContracts, walletChainId, appChainConfig } = useAppChainConfigStore(state => ({
    appContracts: state.appContracts,
    walletChainId: state.appChainId,
    appChainConfig: state.appChainConfig,
  }))
  const { smartWalletAddress, smartWalletClient } = useQuickplaySmartWallet()

  const withdrawCurrencyFromWallet = useCallback(async () => {
    if (!smartWalletAddress || !appContracts || !smartWalletClient) {
      return addAppNoti({
        msg: "You don't have a quickplay wallet",
        type: 'error',
      })
    }

    if (!receiverAddress) {
      return addAppNoti({
        msg: 'Please enter address',
        type: 'error',
      })
    }
    try {
      setIsSendingTx(true)
      const amount = String(withdrawCurrencyAmount)
      const toAddress = receiverAddress
      const shortToAddress = shortenAddress(toAddress)
      const contractAddress = appContracts.currency.address as `0x${string}`

      const transferTxData = encodeFunctionData({
        abi: parseAbi(['function transfer(address,uint256)']),
        functionName: 'transfer',
        args: [
          receiverAddress as any, // who
          parseUnits(String(amount), networkStyle.decimals), // multiplier
        ],
      })

      const transferTx = {
        to: contractAddress,
        data: transferTxData,
      }

      const transferSmartWalletTx = {
        account: smartWalletClient.account,
        calls: [transferTx],
        paymaster: createBicoPaymasterClient({
          paymasterUrl: appChainConfig.biconomyConfig.paymasterUrl,
        }),
        chain: appChainConfig.chainDefinition,
      }

      const uiOptions: SendTransactionModalUIOptions = {
        description: `Withdrawing ${networkStyle.currencyName} to ${shortToAddress}`,
        buttonText: `Withdraw ${networkStyle.currencyName}`,
        successHeader: `Successfully sent ${networkStyle.currencyName}`,
        successDescription: `You have just sent ${networkStyle.currencyName} to ${shortToAddress}`,
        transactionInfo: {
          title: `Transfer ${networkStyle.currencyName}`,
          action: `Transfer ${networkStyle.currencyName}`,
          contractInfo: {
            name: networkStyle.currencyName,
            imgUrl: networkStyle.currencyIcon,
            url: 'https://app.fareplay.io',
            imgSize: 'sm',
            imgAltText: `${networkStyle.currencyName} icon`,
          },
        },
        showWalletUIs: true,
      }

      const transferTxHash = await smartWalletClient.sendTransaction(transferSmartWalletTx, {
        uiOptions,
      })

      postlog(`user_withdrew_currency_from_privy_wallet`, {
        loglevel: 'success',
        eventName: `user_withdrew_currency_from_privy_wallet`,
        to: contractAddress,
        chainId: walletChainId,
        from: smartWalletAddress,
        amount,
        txHash: transferTxHash,
      })

      addAppNoti({
        msg: 'Sent ' + networkStyle.nativeToken,
        type: 'success',
      })
    } catch (err) {
      console.error(err)
      addAppNoti({
        msg: 'Issue with withdraw tx',
        type: 'error',
      })
    } finally {
      setIsSendingTx(false)
    }
  }, [
    smartWalletAddress,
    appContracts,
    smartWalletClient,
    receiverAddress,
    withdrawCurrencyAmount,
    networkStyle.decimals,
    networkStyle.currencyName,
    networkStyle.currencyIcon,
    networkStyle.nativeToken,
    appChainConfig.biconomyConfig.paymasterUrl,
    appChainConfig.chainDefinition,
    postlog,
    walletChainId,
  ])

  if (!isVisible) return null

  return (
    <ModalCard
      title='Withdraw Quickplay Wallet Funds'
      description={
        <p style={{ width: '80%', fontFamily: 'system-ui, sans-serif' }}>
          Withdraw your funds from your Quickplay wallet to your external wallet.
        </p>
      }
      isVisible={isVisible}
      setIsVisible={setWithdrawPrivyModal}
      className='fund-modal-content'
      style={{ height: 'fit-content' }}
    >
      <WithdrawalContent>
        <SInputWrapper>
          <SInput
            placeholder='Withdraw address...'
            value={receiverAddress}
            onChange={event => setReceiverAddress(event.currentTarget.value)}
          />
        </SInputWrapper>
        <BalanceContent className='balance-content-column'>
          <SectionDescription>
            Your current balance to deposit:
            <CountUp
              style={{
                fontFamily: 'system-ui, sans-serif',
                color: 'white',
              }}
              end={currencyBalance}
              decimals={2}
              duration={2}
              separator={','}
              preserveValue
            />
          </SectionDescription>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <WithdrawInput
              decimals={2}
              amount={withdrawCurrencyAmount}
              setAmount={setWithdrawCurrencyAmount}
              tokenName={networkStyle.currencyName}
              tokenIcon={networkStyle.currencyIcon}
              max={currencyBalance}
            />
            <WithdrawalButton
              buttonType={ButtonEnum.BASE}
              type='button'
              isLoading={isSendingTx}
              onClick={withdrawCurrencyFromWallet}
              disabled={withdrawCurrencyAmount <= 0 || isSendingTx}
            >
              WITHDRAW FUNDS
              <img src={SVGS.depositWallet} alt='deposit wallet' />
            </WithdrawalButton>
          </div>
        </BalanceContent>
      </WithdrawalContent>
      <FundPageButton
        buttonType={ButtonEnum.BASE}
        type='button'
        disabled={false}
        onClick={exportWallet}
        isLoading={false}
        style={{ border: 'none' }}
      >
        <FundPageButtonWrapper>
          <ButtonText>EXPORT WALLET</ButtonText>
        </FundPageButtonWrapper>
      </FundPageButton>
    </ModalCard>
  )
}
