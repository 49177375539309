import useUserDataStore from '@/store/useUserDataStore'
import { addAppNoti } from '@/store/useNotiStore'
import { useUserApi } from '@/lib/useUserApi'
import { useActiveWallet } from '@/lib/privy/hooks'

const useUserData = () => {
  const { walletAddress } = useActiveWallet()
  const {
    username,
    referralCode,
    referredUsers,
    setUsername,
    avatarSeedAddress,
    setAvatarSeedAddress,
    isUsingTwitterToDisplay,
    setIsUsingTwitterToDisplay,
    twitterUsername,
    twitterDisplayName,
    twitterImage,
    deathmatchPoints,
    latestSUEntries,
    setTwitterUsername,
    setTwitterDisplayName,
    setTwitterImage,
    setTwitterAccount,
    setDeathmatchPoints,
    setLatestSUEntries,
  } = useUserDataStore()
  const userApi = useUserApi()

  const requestToSaveUsername = async (newUsername: string) => {
    if (!walletAddress) return

    try {
      const { message } = await userApi.updateUserUsername(newUsername)
      if (message) {
        setUsername(newUsername)
        addAppNoti({
          type: 'success',
          msg: 'Username updated',
        })
      } else {
        addAppNoti({
          type: 'error',
          msg: 'Failed: username update',
        })
        throw new Error('Error while setting username')
      }
    } catch (err) {
      if ((err as unknown as any).response.data.message) {
        addAppNoti({
          msg: `${(err as any).response.data.message}`,
          type: 'error',
        })
        throw new Error('Error while setting username')
      } else {
        addAppNoti({
          msg: `Failed: username update`,
          type: 'error',
        })
        throw new Error('Error while setting username')
      }
    }
  }

  const requestToRerollAvatarSeed = async () => {
    if (!walletAddress) return
    try {
      const { newAvatarSeedAddress } = await userApi.updateAvatarSeedAddress()
      if (newAvatarSeedAddress) {
        setAvatarSeedAddress(newAvatarSeedAddress)
        addAppNoti({
          msg: `Avatar updated`,
          type: 'success',
        })
      } else {
        addAppNoti({
          msg: `Failed: avatar update`,
          type: 'error',
        })
        throw new Error('Error while rerolling avatar seed')
      }
    } catch (err) {
      if ((err as unknown as any).response.data.message) {
        addAppNoti({
          msg: `${(err as any).response.data.message}`,
          type: 'error',
        })
        throw new Error('Error while rerolling avatar seed')
      } else {
        addAppNoti({
          msg: `Failed: avatar update`,
          type: 'error',
        })
        throw new Error('Error while rerolling avatar seed')
      }
    }
  }

  const requestToSaveIsUsingTwitterToDisplay = async (newIsUsingTwitterToDisplay: boolean) => {
    if (!walletAddress) return
    try {
      const { message } = await userApi.updateIsUsingTwitterToDisplay(newIsUsingTwitterToDisplay)
      if (message) {
        setIsUsingTwitterToDisplay(newIsUsingTwitterToDisplay)
        addAppNoti({
          msg: newIsUsingTwitterToDisplay ? `Twitter display enabled` : `Twitter display disabled`,
          type: 'success',
        })
      } else {
        addAppNoti({
          msg: `Failed: enable twitter display`,
          type: 'error',
        })
        throw new Error('Error while updating isUsingTwitterToDisplay')
      }
    } catch (err) {
      if ((err as unknown as any).response.data.message) {
        addAppNoti({
          msg: `${(err as any).response.data.message}`,
          type: 'error',
        })
        throw new Error('Error while updating isUsingTwitterToDisplay')
      } else {
        addAppNoti({
          msg: `Failed: enable twitter display`,
          type: 'error',
        })
        throw new Error('Error while updating isUsingTwitterToDisplay')
      }
    }
  }

  const requestToDisconnectTwitter = async () => {
    if (!walletAddress) return
    try {
      const { message } = await userApi.unlinkUserTwitterAccount()
      if (message) {
        setIsUsingTwitterToDisplay(false)
        setTwitterAccount({})
        addAppNoti({
          msg: `Twitter disconnected`,
          type: 'success',
        })
      } else {
        addAppNoti({
          msg: `Failed: twitter update`,
          type: 'error',
        })
        throw new Error('Error while disconnecting twitter')
      }
    } catch (err) {
      if ((err as unknown as any).response.data.message) {
        addAppNoti({
          msg: `${(err as any).response.data.message}`,
          type: 'error',
        })
        throw new Error('Error while disconnecting twitter')
      } else {
        addAppNoti({
          msg: `Failed: twitter update`,
          type: 'error',
        })
        throw new Error('Error while disconnecting twitter')
      }
    }
  }

  return {
    username,
    referralCode,
    referredUsers,
    setUsername,
    avatarSeedAddress,
    setAvatarSeedAddress,
    isUsingTwitterToDisplay,
    setIsUsingTwitterToDisplay,
    twitterUsername,
    setTwitterUsername,
    twitterDisplayName,
    setTwitterDisplayName,
    twitterImage,
    setTwitterImage,
    setTwitterAccount,
    deathmatchPoints,
    setDeathmatchPoints,
    latestSUEntries,
    setLatestSUEntries,
    requestToSaveUsername,
    requestToRerollAvatarSeed,
    requestToSaveIsUsingTwitterToDisplay,
    requestToDisconnectTwitter,
  }
}

export default useUserData
