import { createPortal } from 'react-dom'
import { SVGS } from '@/assets'
import ShareWinningsModal from '@/components/Modals/ShareWinningsModal'
import { useShareWinningsModal } from '@/hooks/useShareWinningsModal'
import { BORDER_COLORS, BREAKPOINTS } from '@/design'

const ShareWinningsIcon = styled.button`
  position: absolute;
  background-color: transparent;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  aspect-ratio: 1/1;
  border-color: ${BORDER_COLORS.one};
  border-style: dashed;
  border-width: 1px;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
  touch-action: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-style: solid;
    background-color: #52525226;
  }

  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    top: 10px;
    width: 30px;
    height: 30px;
  }
`

export default function ShareWinningsIconButton() {
  const { isModalOpen, handleShareWinningsClick, handleCloseModal } = useShareWinningsModal()

  return (
    <>
      <ShareWinningsIcon onClick={handleShareWinningsClick}>
        <img src={SVGS.copyIcon} alt='Copy to clipboard' width={24} />
      </ShareWinningsIcon>
      {isModalOpen &&
        createPortal(<ShareWinningsModal onClose={handleCloseModal} />, document.body)}
    </>
  )
}
