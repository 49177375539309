import { GameGrid } from '@/components/shared/GameGrid/GameGrid'
import { Bloom, EffectComposer } from '@react-three/postprocessing'
import { PerspectiveCamera } from '@react-three/drei'
import React, { useRef, useMemo, useState, useEffect } from 'react'
import { RouletteWheel } from './RouletteWheel'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import { RouletteBall } from './RouletteBall'
import { type RouletteEntry, useRouletteGameStore } from '@/store/useRouletteGameStore'
import { AnimatedResultText } from './AnimatedResultText'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import { useRouletteSound } from './RouletteSoundInterface'
import { entryEvent } from '@/events/entryEvent'
import { useRouletteGameState } from '@/store/useGameStateStore'
import { useShallow } from 'zustand/react/shallow'

interface RouletteSceneProps {
  isDrawerOpen: boolean
  onResultShown?: () => void
}

export const calculateWinIntensity = (entry: RouletteEntry): number => {
  // Base intensity on bet type and potential payout
  switch (entry.type) {
    case 'straight':
      return 5 // Highest payout (35:1)
    case 'split':
      return 4 // 17:1 payout
    case 'street':
      return 3 // 11:1 payout
    case 'trio':
      return 3 // 11:1 payout
    case 'corner':
      return 2 // 8:1 payout
    case 'line':
      return 2 // 5:1 payout
    case 'dozen':
      return 1 // 2:1 payout
    case 'column':
      return 1 // 2:1 payout
    case 'redBlack':
      return 1 // 1:1 payout
    case 'oddEven':
      return 1 // 1:1 payout
    case 'highLow':
      return 1 // 1:1 payout
    default:
      return 1
  }
}

export const RouletteScene: React.FC<RouletteSceneProps> = ({ isDrawerOpen, onResultShown }) => {
  const cameraRef = useRef<THREE.PerspectiveCamera>(null)
  const [wheelRotation, setWheelRotation] = useState(0)
  const { gameState, endRound, result, currentEntries, backendResult } = useRouletteGameStore()
  const [showResultText, setShowResultText] = useState(false)
  const setIsShowingOutcome = useGameOutcomeStore(state => state.setIsShowingOutcome)
  const setDidPlayerWin = useGameOutcomeStore(state => state.setDidPlayerWin)
  const setWinIntensity = useGameOutcomeStore(state => state.setIntensity)
  const { playGameSound } = useRouletteSound()
  const { send, gameStateType } = useRouletteGameState(
    useShallow(state => ({ gameStateType: state.type, send: state.send }))
  )

  const vectors = useMemo(
    () => ({
      closedPositionPlaying: new THREE.Vector3(0, 4.25, 2.5),
      openPositionPlaying: new THREE.Vector3(0, 4, 4),
      closedLookAtPlaying: new THREE.Vector3(0, 0.5, 0),
      openLookAtPlaying: new THREE.Vector3(0, -1.5, 0),
      closedPositionIdle: new THREE.Vector3(0, 4.25 * 1.1, 2.5 * 1.1),
      openPositionIdle: new THREE.Vector3(0, 4 * 1.1, 4 * 1.1),
      closedLookAtIdle: new THREE.Vector3(0, 0.5, 0),

      openLookAtIdle: new THREE.Vector3(0, -1.5, 0),

      currentLookAt: new THREE.Vector3(),
      targetDirection: new THREE.Vector3(),
      newDirection: new THREE.Vector3(),
      tempVector: new THREE.Vector3(),
    }),
    []
  )

  const handleWheelRotation = (rotation: number) => {
    setWheelRotation(rotation)
  }

  useEffect(() => {
    if (gameState === 'RESULT_SHOWN' && result !== undefined) {
      // Check if any entries are winning by checking if the result is in their numbers
      const winningEntries = currentEntries.filter(entry => {
        return entry.numbers.includes(result)
      })

      if (winningEntries.length > 0) {
        // Find the highest intensity among winning entries
        const highestIntensity = Math.max(
          ...winningEntries.map(entry => calculateWinIntensity(entry))
        )

        setIsShowingOutcome(true)
        setDidPlayerWin(true)
        setWinIntensity(highestIntensity)
        playGameSound('win', 0.4, 1.5)
      } else {
        setIsShowingOutcome(true)
        setDidPlayerWin(false)
        setWinIntensity(1)
        playGameSound('lose', 0.4, 1)
      }
    } else if (gameState === 'RESETTING') {
      setIsShowingOutcome(false)
      setDidPlayerWin(false)
      setWinIntensity(1)
    }
  }, [gameState, result, currentEntries, setIsShowingOutcome, setDidPlayerWin, setWinIntensity])

  useEffect(() => {
    if (gameState === 'PLAYING') {
      playGameSound('beep', 0.4, 1.5)
    }
  }, [gameState, playGameSound])

  useFrame(() => {
    if (!cameraRef.current) return

    let targetPos, targetLookAt

    if (gameState === 'IDLE' || gameState === 'RESETTING') {
      // Use idle camera positions
      targetPos = isDrawerOpen ? vectors.openPositionIdle : vectors.closedPositionIdle
      targetLookAt = isDrawerOpen ? vectors.openLookAtIdle : vectors.closedLookAtIdle
    } else {
      // Use playing camera positions for all other states (PLAYING, RESULT_SHOWN, etc)
      targetPos = isDrawerOpen ? vectors.openPositionPlaying : vectors.closedPositionPlaying
      targetLookAt = isDrawerOpen ? vectors.openLookAtPlaying : vectors.closedLookAtPlaying
    }

    cameraRef.current.position.lerp(targetPos, 0.02)

    cameraRef.current.getWorldDirection(vectors.currentLookAt)
    vectors.targetDirection.copy(targetLookAt).sub(cameraRef.current.position).normalize()
    vectors.newDirection.lerpVectors(vectors.currentLookAt, vectors.targetDirection, 0.01)

    vectors.tempVector.copy(cameraRef.current.position).add(vectors.newDirection.multiplyScalar(10))
    cameraRef.current.lookAt(vectors.tempVector)
  })

  const handleResultShown = () => {
    onResultShown?.()
    endRound()
  }

  useEffect(() => {
    if (gameState === 'RESULT_SHOWN') {
      setShowResultText(true)
      entryEvent.pub('updateBalance')
      entryEvent.pub('entryFinished', {
        deltaAmount: backendResult.deltaAmounts[0], // deltaAmount
      })
    } else if (gameState === 'RESETTING') {
      setShowResultText(false)
      setTimeout(() => {
        entryEvent.pub('gameFinished')
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState])

  // This helps us to reset the state related to the way Tim is doing it. Roulette itself has some other state as well, which is implemented by Nathan that he uses to do some other stuff, so we gotta handle both game states
  useEffect(() => {
    switch (gameStateType) {
      case 'RESET':
        send({ type: 'IDLE', payload: {} })
        break
      case 'ERROR':
        send({ type: 'RESET', payload: {} })
        break
      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStateType])

  return (
    <>
      <PerspectiveCamera
        ref={cameraRef}
        makeDefault
        position={[0, 0, 4]}
        fov={60}
        near={0.1}
        far={1000}
      />

      <EffectComposer>
        <Bloom luminanceThreshold={0.25} luminanceSmoothing={3} intensity={0.12} />
      </EffectComposer>
      <ambientLight intensity={7} />
      <directionalLight position={[5, 10, -7.5]} intensity={2.5} />
      <pointLight position={[2.758, 3.5, 2.758]} intensity={0} distance={20} />
      <pointLight position={[-2.758, 3.5, -2.758]} intensity={0} distance={20} />
      <pointLight position={[2.758, 3.5, -2.758]} intensity={0} distance={20} />
      <pointLight position={[2.758, 3.5, -2.758]} intensity={0} distance={20} />

      <RouletteWheel
        position={[0, 0, 0]}
        onRotationUpdate={handleWheelRotation}
        showTrigger={false}
      />

      <RouletteBall
        position={[0, 0.85, 0]}
        radius={1.82}
        speed={6}
        center={[0, 0.12, 0]}
        wheelRotation={wheelRotation}
        onResultShown={handleResultShown}
      />

      <GameGrid
        position={[0, -10, 0]}
        size={[300, 300]}
        color='#000000'
        emissive='#181717'
        emissiveIntensity={0.5}
        opacity={0.8}
        gridSpacing={3}
      />

      {showResultText && gameState === 'RESULT_SHOWN' && result !== undefined && (
        <AnimatedResultText result={result} isVisible={false} />
      )}
    </>
  )
}
