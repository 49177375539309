import * as THREE from 'three'
import React, { useRef, useEffect, useMemo, useState, useCallback } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { type GLTF } from 'three-stdlib'
import { useFrame, useThree } from '@react-three/fiber'
import { Vector3 } from 'three'
import { useRouletteGameStore } from '@/store/useRouletteGameStore'
import { useSpring, animated } from '@react-spring/three'
import { useRouletteSound } from './RouletteSoundInterface'
import { FARE_COLORS } from '@/design/colors'

// Define the structure of our 3D model
type GLTFResult = GLTF & {
  nodes: {
    BallAnimated: THREE.Mesh
  }
  materials: {
    gold: THREE.MeshStandardMaterial
  }
}

interface RouletteBallProps {
  position: [number, number, number]
  radius: number
  speed: number
  center: [number, number, number]
  wheelRotation: number
  onResultShown?: () => void
}

const MODEL_PATH = '/glb/RouletteBall.glb'
// const ballColor = FARE_COLORS.pink
// const ballColor = FARE_COLORS.aqua
// const ballColor = FARE_COLORS.blue
// const ballColor = FARE_COLORS.peach
const ballColor = '#ffffff'
// const ballColor = '#d3d7fc'

const ballMaterial = new THREE.MeshStandardMaterial({
  color: ballColor,
  metalness: 1,
  roughness: 0.75,
  envMapIntensity: 1,
  emissive: ballColor,
  emissiveIntensity: 0.45,
})

// Helper to get a random animation variant for a result number
const getRandomVariant = (baseResult: number, animations: THREE.AnimationClip[]): string => {
  const pattern = new RegExp(`^Result_${baseResult}(?:\\.\\d+)?$`)
  const variants = animations.map(clip => clip.name).filter(name => pattern.test(name))
  return variants.length > 0 ?
      variants[Math.floor(Math.random() * variants.length)]
    : `Result_${baseResult}`
}

export const RouletteBall: React.FC<RouletteBallProps> = ({
  position,
  radius,
  speed,
  center,
  wheelRotation,
}) => {
  // Refs for 3D objects
  const orbitGroupRef = useRef<THREE.Group>(null)
  const ballGroupRef = useRef<THREE.Group>(null)
  const { nodes, materials, animations } = useGLTF(MODEL_PATH) as GLTFResult
  const { actions } = useAnimations(animations, ballGroupRef)

  // State and refs for ball motion
  const totalAngle = useRef(0)
  const { scene } = useThree()
  const lastPosition = useRef(new Vector3())
  const [lastZeroCrossing, setLastZeroCrossing] = useState(0)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [isInsideTrigger, setIsInsideTrigger] = useState(false)
  const [isVisible, setIsVisible] = useState(true)

  // Reusable vectors for calculations
  const triggerBox = useMemo(() => new THREE.Box3(), [])
  const ballWorldPos = useMemo(() => new Vector3(), [])

  // Get game state
  const { result, isSpinning, canShowResult, gameState } = useRouletteGameStore()

  // Add spring animation for opacity
  const { opacity } = useSpring({
    opacity: isVisible ? 1 : 0,
    config: { duration: 300 },
  })

  const { playGameSound } = useRouletteSound()
  const lastBeepTime = useRef(0)

  // Reset the ball to initial position
  const resetBall = useCallback(() => {
    if (orbitGroupRef.current && ballGroupRef.current) {
      Object.values(actions).forEach(action => action?.stop())

      orbitGroupRef.current.position.set(center[0], position[1], center[2])
      orbitGroupRef.current.rotation.y = 0
      ballGroupRef.current.position.set(radius, 0.1, 0)
      ballGroupRef.current.rotation.set(0, 0, 0)
      totalAngle.current = 0

      setIsTransitioning(false)
    }
  }, [actions, center, position, radius])

  // Handle transition to result animation
  useEffect(() => {
    if (!isSpinning && canShowResult && !isTransitioning) {
      setIsTransitioning(true)
      const action = actions[getRandomVariant(result!, animations) as keyof typeof actions]
      if (action) {
        action.reset()
        action.clampWhenFinished = true
        action.loop = THREE.LoopOnce
        action.play()
      }
    }
  }, [canShowResult, isSpinning, isTransitioning, result, actions, animations, resetBall])

  // Handle visibility based on game state
  useEffect(() => {
    if (gameState === 'RESETTING') {
      setIsVisible(false)
    } else if (gameState === 'IDLE' && !isVisible) {
      resetBall()
      setIsVisible(true)
    }
  }, [gameState, resetBall, isVisible])

  // Main animation loop
  useFrame((state, delta) => {
    if (!orbitGroupRef.current || !ballGroupRef.current) return

    if (isSpinning && !isTransitioning) {
      // Update ball orbital motion
      totalAngle.current = (totalAngle.current - speed * delta) % (2 * Math.PI)
      orbitGroupRef.current.position.set(center[0], position[1], center[2])
      orbitGroupRef.current.rotation.y = totalAngle.current
      ballGroupRef.current.position.set(radius, 0.1, 0)
      ballGroupRef.current.rotation.set(0, 0, 0)

      // Get ball's world position
      ballGroupRef.current.getWorldPosition(ballWorldPos)

      // Get trigger volume
      const trigger = scene.getObjectByName('ZeroTrigger')
      if (trigger) {
        // Get trigger's world bounds
        triggerBox.setFromObject(trigger)

        const isInTrigger = triggerBox.containsPoint(ballWorldPos)

        // Only trigger when we first enter the volume and game state is correct
        if (isInTrigger && !isInsideTrigger) {
          const timeSinceLastCrossing = state.clock.getElapsedTime() - lastZeroCrossing

          if (timeSinceLastCrossing > 0.1) {
            setLastZeroCrossing(state.clock.getElapsedTime())

            // Play a tick sound when hitting trigger, if in playing state
            if (gameState === 'PLAYING') {
              playGameSound('beep', 0.15, 1.8)
            }


            // Only trigger if we're in PLAYING state and canShowResult is true
            if (
              gameState === 'PLAYING' &&
              canShowResult &&
              typeof result === 'number' &&
              !isTransitioning
            ) {
              // console.log('DEBUG: Starting result animation', { result })
              setIsTransitioning(true)

              // Stop any current animations and reset positions
              Object.values(actions).forEach(action => action?.stop())

              // Start the result animation
              const animationName = getRandomVariant(result, animations)
              const action = actions[animationName as keyof typeof actions]
              if (action) {
                // Set final positions before starting result animation
                orbitGroupRef.current.position.set(center[0], position[1], center[2])
                orbitGroupRef.current.rotation.y = 0
                ballGroupRef.current.position.set(0, -0.9, 0)
                ballGroupRef.current.rotation.y = wheelRotation

                action.reset()
                action.clampWhenFinished = true
                action.loop = THREE.LoopOnce
                action.play()

                // Alert the store that the ball animation has started
                useRouletteGameStore.getState().alertBallStartedAnimation()
              }
              return
            }
          }
        }

        setIsInsideTrigger(isInTrigger)
      }

      lastPosition.current.copy(ballWorldPos)
    }
  })

  if (!nodes || !materials) return null

  return (
    <group ref={orbitGroupRef}>
      <group ref={ballGroupRef} position={[0, 0.1, 0]}>
        <animated.mesh
          name='BallAnimated'
          castShadow
          receiveShadow
          geometry={nodes.BallAnimated.geometry}
          material={ballMaterial}
          scale={1.982}
          visible={opacity.to((o: number) => o > 0)}
          material-opacity={opacity}
        />
      </group>
    </group>
  )
}

useGLTF.preload(MODEL_PATH)
