import { analyticsApi, IAnalytics } from '@/lib/http/analyticsApi'
import { SGameStatRow, DynamicBorder, SGameStatBoxWrapper, SGameStateDataWrapper } from './styles'

export const UserGameStats = ({ walletAddress }: { walletAddress: string }) => {
  const [stats, setStats] = useState<IAnalytics | null>(null)
  const fetchAndSetGameStats = useCallback(async () => {
    try {
      const data = await analyticsApi.getUserAnalyticsStats(walletAddress)
      setStats(data)
    } catch (err) {
      console.error(err)
    }
  }, [walletAddress])

  useEffect(() => {
    if (!walletAddress) return
    fetchAndSetGameStats()
  }, [walletAddress])

  if (!stats) return null

  return (
    <>
      <SGameStatRow>
        <DynamicBorder $statColor={'#00FFFF'}>
          <td>
            <SGameStatBoxWrapper>BIGGEST WIN</SGameStatBoxWrapper>
            <SGameStateDataWrapper>{stats.biggestWinAmount}</SGameStateDataWrapper>
          </td>
        </DynamicBorder>

        <DynamicBorder $statColor={'#FA8072'}>
          <td>
            <SGameStatBoxWrapper>BIGGEST LOSS</SGameStatBoxWrapper>
            <SGameStateDataWrapper>{stats.biggestLossAmount}</SGameStateDataWrapper>
          </td>
        </DynamicBorder>
        <DynamicBorder $statColor={'#FFDAB9'}>
          <td>
            <SGameStatBoxWrapper>AVG BET</SGameStatBoxWrapper>
            <SGameStateDataWrapper>{stats.averageBetAmount}</SGameStateDataWrapper>
          </td>
        </DynamicBorder>
        <DynamicBorder $statColor={'#FFFFFF'}>
          <td>
            <SGameStatBoxWrapper>TOTAL VOLUME</SGameStatBoxWrapper>
            <SGameStateDataWrapper>{stats.totalBetVolume}</SGameStateDataWrapper>
          </td>
        </DynamicBorder>
      </SGameStatRow>
      <SGameStatRow>
        <DynamicBorder $statColor={'#00FFFF'}>
          <td>
            <SGameStatBoxWrapper>WIN STREAK</SGameStatBoxWrapper>
            <SGameStateDataWrapper>{stats.longestWinStreak}</SGameStateDataWrapper>
          </td>
        </DynamicBorder>

        <DynamicBorder $statColor={'#FA8072'}>
          <td>
            <SGameStatBoxWrapper>LOSS STREAK</SGameStatBoxWrapper>
            <SGameStateDataWrapper>{stats.longestLoseStreak}</SGameStateDataWrapper>
          </td>
        </DynamicBorder>
        <DynamicBorder $statColor={'#FFDAB9'}>
          <td>
            <SGameStatBoxWrapper>MOST PLAYED</SGameStatBoxWrapper>
            <SGameStateDataWrapper>
              {(stats.mostPlayedGameName || '').toUpperCase() || 'N/A'}
            </SGameStateDataWrapper>
          </td>
        </DynamicBorder>
        <DynamicBorder $statColor={'#FFFFFF'}>
          <td>
            <SGameStatBoxWrapper>TOTAL BETS</SGameStatBoxWrapper>
            <SGameStateDataWrapper>{stats.totalBetCount}</SGameStateDataWrapper>
          </td>
        </DynamicBorder>
      </SGameStatRow>
      <SGameStatRow>
        <DynamicBorder $statColor={'#00FFFF'}>
          <td>
            <SGameStatBoxWrapper>HIGHEST X</SGameStatBoxWrapper>
            <SGameStateDataWrapper>{stats.highestMultiplier}</SGameStateDataWrapper>
          </td>
        </DynamicBorder>

        <DynamicBorder $statColor={'#FA8072'}>
          <td>
            <SGameStatBoxWrapper>TOTAL LOSS</SGameStatBoxWrapper>
            <SGameStateDataWrapper>{stats.totalLoseAmount}</SGameStateDataWrapper>
          </td>
        </DynamicBorder>
        <DynamicBorder $statColor={'#FFDAB9'}>
          <td>
            <SGameStatBoxWrapper>TOTAL PROFITS</SGameStatBoxWrapper>
            <SGameStateDataWrapper>{stats.totalWinAmount}</SGameStateDataWrapper>
          </td>
        </DynamicBorder>
        <DynamicBorder $statColor={'#FFFFFF'}>
          <td>
            <SGameStatBoxWrapper>DAILY STREAK</SGameStatBoxWrapper>
            <SGameStateDataWrapper>{stats.dailyStreak}</SGameStateDataWrapper>
          </td>
        </DynamicBorder>
      </SGameStatRow>
    </>
  )
}
