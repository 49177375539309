import { PNGS } from '@/assets'
import { Button } from '@/components/shared/Button'
import { FARE_COLORS } from '@/design'

const BUTTON_HEIGHT = '60px'
const MARGIN = '5px'
const GAP = '10px'
const BORDER_RADIUS = '10px'
const FONT_SMALL = '12px'
const FONT_MEDIUM = '16px'
const FONT_LARGE = '20px'
const FONT_XLARGE = '40px'

export const ShareWinningsCard = styled.div`
  width: 290px;
  padding: 1% 5%;
  border: 2px solid #cb02d980;
  border-radius: ${BORDER_RADIUS};
  background-color: black;
  background-image: url(${PNGS.shareCardBackground});
  background-size: 100%;
  background-repeat: no-repeat;
`
export const ShareWinningsTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${GAP};
  padding: 5% 0 14%;
`

export const ShareWinningsDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  margin: 30px 0;

  p {
    margin: ${MARGIN};
    font-size: ${FONT_MEDIUM};
  }

  .gradient-text {
    font-size: ${FONT_XLARGE};
    font-weight: 700;
    line-height: 1;
    background: linear-gradient(
      90deg,
      ${FARE_COLORS.blue},
      ${FARE_COLORS.pink},
      ${FARE_COLORS.salmon} 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  span {
    background-color: ${FARE_COLORS.aqua};
    padding: 4px 8px;
    border-radius: 30px;
    color: black;
    font-size: ${FONT_SMALL};
    font-weight: 700;
    vertical-align: bottom;
  }

  &.current-pnl {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: ${FONT_LARGE};
    flex-direction: row;
    gap: ${GAP};
  }
`

export const ShareWinnngsBlocks = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-block: ${GAP};
  border: 1px solid #680371;
  border-radius: ${BORDER_RADIUS};
  background: linear-gradient(180deg, #05050a, #100227 100%);
  padding-block: ${GAP};

  &.stat-block {
    flex-direction: column;
    justify-content: space-between;

    p {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin-block: ${MARGIN};
    }

    span {
      margin-left: auto;
    }
  }

  &.fare-site-block {
    flex-direction: row;
    gap: ${GAP};
    padding: 6px 0;

    img {
      margin: 0;
    }

    p {
      color: #fff;
      font-size: ${FONT_MEDIUM};
      font-weight: 700;
      text-transform: uppercase;
    }
  }
`

export const ShareWinningsButton = styled(Button)`
  width: 330px;
  border-radius: ${BORDER_RADIUS};
  height: ${BUTTON_HEIGHT};
  background: ${FARE_COLORS.aqua}30;
  box-shadow: 0px 1px 6px #4af5d3;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${GAP};
    margin: 0;
    font-size: ${FONT_LARGE};
  }
`
