import { defaultBiconomyConfig } from './biconomy.config'

export const defaultAppChainConfig = {
  websocketUrl: 'http://localhost:3337',
  httpUrl: 'http://localhost:3200',
  rpc: {
    chainId: 1,
    ws: 'wss://eth-mainnet.g.alchemy.com/v2/CpvQpzA6sCMEjR8uaYXX5-kSoUd-UZs0',
    http: 'https://eth-mainnet.g.alchemy.com/v2/CpvQpzA6sCMEjR8uaYXX5-kSoUd-UZs0',
  },
  features: {
    enableMobile: true,
    enablePosthog: true,
    enableFaucet: false,
  },
  services: {
    alchemy: {
      apiKey: 'CpvQpzA6sCMEjR8uaYXX5-kSoUd-UZs0',
    },
    posthog: {
      host: 'https://ph.fareplay.io',
      key: 'phc_BXiP1rXp3y5F51NxIqs9iz679FFb9bI89X89CphyYf4',
    },
    walletConnect: {
      projectId: 'ca77197d67b9942f9ef7f4476b23f85f',
    },
  },
  chainDefinition: {} as any,
  biconomyConfig: defaultBiconomyConfig,
} as const
