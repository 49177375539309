import { ButtonEnum } from '@/components/shared/Button'
import ModalCard from '@/components/shared/Modal/Card'
import { ButtonText, FundPageButton, FundPageButtonWrapper } from '../styles'
import { useQuickplaySmartWallet } from '../../../hooks/useQuickplaySmartWallet'

interface IQuickPlayModal {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  formData: any
}

const QuickplayContent = styled.div`
  padding-bottom: 32px;

  p {
    font-family: system-ui, sans-serif;
  }
`

export default function QuickPlayModal({ isVisible, setIsVisible, formData }: IQuickPlayModal) {
  const handleClose = () => {
    setIsVisible(false)
  }

  const { batchQuickplaySetupTxs } = useQuickplaySmartWallet()
  const [isApproving, setIsApproving] = useState(false)

  const setup = useCallback(async () => {
    try {
      setIsApproving(true)
      await batchQuickplaySetupTxs(formData, () => {
        setIsVisible(false)
      })
    } catch (err) {
      console.error(err)
    } finally {
      setIsApproving(false)
    }
  }, [formData, batchQuickplaySetupTxs])

  return (
    <ModalCard
      title='Setting up Quickplay'
      description='Connect to privy wallet'
      isVisible={isVisible}
      setIsVisible={handleClose}
      className='fund-modal-content'
      style={{ height: 'fit-content' }}
    >
      <QuickplayContent>
        <p>
          Quickplay allows you to submit lightning fast, 1-click wagers without having to sign a
          transaction in your wallet.
        </p>
        <p>
          In order to use Quickplay, a new Privy wallet will be generated for you to gamble from.
        </p>
        <p>Only you control this wallet. Simply fund it with USDC and start playing!</p>
      </QuickplayContent>
      <FundPageButton
        buttonType={ButtonEnum.BASE}
        type='button'
        disabled={isApproving}
        onClick={setup}
        isLoading={isApproving}
        loadingText={'APPROVING'}
      >
        <FundPageButtonWrapper>
          <ButtonText>APPROVE</ButtonText>
        </FundPageButtonWrapper>
      </FundPageButton>
    </ModalCard>
  )
}
