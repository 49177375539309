// import { AnimatePresence } from 'framer-motion'
// import { FundWalletNotiPill } from '../NotiPill/FundWalletNotiPill'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'

const SCenterHeader = styled.div<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  ${({ $isMobile }) => ($isMobile ? 'flex: 1' : 'width: 100px')};

  @media (max-width: 1000px) {
    display: none;
  }
`

export const CenterHeader = () => {
  const isMobileScreen = useIsBreakpoint('sm')

  return (
    <SCenterHeader $isMobile={isMobileScreen}>
      {/* <AnimatePresence>{isMobileScreen ? null : <FundWalletNotiPill />}</AnimatePresence> */}
    </SCenterHeader>
  )
}
