import { MountFSocket } from './MountFSocket'
import { AllowanceListener } from './AllowanceListener'
import { BalanceListener } from './BalanceListener'
import { AASessionListener } from './AASessionListener'
import { RouteParamsListener } from './RouteParamsListener'
import { AppChainConfigListener } from './AppChainConfigListener'
import { PathNameListener } from './PathNameListener'
import { PrivyLoadedListener } from './PrivyLoadedListener'
import { PrivyQuickplaySmartWalletListener } from './PrivyQuickplaySmartWalletListener'
import { ReferralListener } from './ReferralListener'
import { SmartWalletListener } from './SmartWalletListener'

export function SingletonListeners() {
  return (
    <>
      <ReferralListener />
      <PathNameListener />
      <AppChainConfigListener />
      <BalanceListener />
      <AllowanceListener />
      <MountFSocket />
      <AASessionListener />
      <RouteParamsListener />
      <PrivyLoadedListener />
      <PrivyQuickplaySmartWalletListener />
      <SmartWalletListener />
    </>
  )
}
