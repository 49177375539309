import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { useActiveWallet } from '@/lib/privy/hooks'
import { WalletOverview } from '@/components/Privy/WalletOverview'
import { AccountWrapper, RightHeader } from './style'
import { AppNoti } from '../AppNoti'
import ConnectWallet from '../Wallet/ConnectWallet'
import { MobileMenu } from '@/components/Mobile/MobileMenu'
import { Dropdown } from '../../Mobile/Dropdown'
import { SwitchCurrency } from '../CurrencySwitch'
import NetworkSwitchButton from '@/components/SwitchNetworkButton'
import { GameMenuDropdown } from './GameMenuDropdown'
import { useState, useRef, useCallback, useMemo } from 'react'

export const RightHeaderContent = () => {
  const isMobileScreen = useIsBreakpoint('xs')
  const { activeWallet, isWalletLinked, isWrongNetwork } = useActiveWallet()
  const [isShowMenu, setShowMenu] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const rightHeaderRef = useRef<HTMLDivElement>(null)

  const handleHeaderClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (rightHeaderRef.current && !rightHeaderRef.current.contains(event.target as Node)) {
        setShowMenu(false)
      }
    },
    [setShowMenu]
  )

  const handleDropdownButtonClick = (isOpen: boolean) => {
    if (isMobileScreen) {
      setIsDropdownOpen(prevState => !prevState)
    } else {
      setIsDropdownOpen(isOpen)
    }
  }

  const handleDropdownContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  const leftButtonRenderer = useMemo(() => {
    if (isWrongNetwork) return <NetworkSwitchButton />
    return null
  }, [isWrongNetwork])

  if (!activeWallet || !isWalletLinked) {
    return (
      <RightHeader>
        <AppNoti />
        <AccountWrapper>
          <ConnectWallet className='default-min-width' />
        </AccountWrapper>
        {isMobileScreen && (
          <>
            <GameMenuDropdown
              setShowMenu={setShowMenu}
              isShowMenu={isShowMenu}
              rightHeaderRef={rightHeaderRef}
            />
            <MobileMenu isShowMenu={isShowMenu} onButtonClick={() => setShowMenu(false)} />
          </>
        )}
      </RightHeader>
    )
  }

  return (
    <RightHeader onClick={handleHeaderClick}>
      <AppNoti />
      {/*!isMobileScreen && <WalletOverview />*/}
      <AccountWrapper>
        {!isMobileScreen && (isWrongNetwork ? leftButtonRenderer : <SwitchCurrency />)}
        <Dropdown
          isOpen={isDropdownOpen}
          onButtonClick={handleDropdownButtonClick}
          onContentClick={handleDropdownContentClick}
        />
      </AccountWrapper>
      {isMobileScreen && <AppNoti />}

      {isMobileScreen && (
        <>
          <GameMenuDropdown
            setShowMenu={setShowMenu}
            isShowMenu={isShowMenu}
            rightHeaderRef={rightHeaderRef}
          />
          <MobileMenu isShowMenu={isShowMenu} onButtonClick={() => setShowMenu(false)} />
        </>
      )}
    </RightHeader>
  )
}
