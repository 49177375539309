import React from 'react'
import { ChipWrapper, ChipCircle } from './styles'
import type { ChipProps } from './types'
import { useRouletteGameStore } from '@/store/useRouletteGameStore'

export const Chip: React.FC<ChipProps> = ({ position, amount, isHover }) => {
  const minBetAmount = useRouletteGameStore(state => state.minBetAmount)

  return (
    <ChipWrapper $x={position.x} $y={position.y}>
      <ChipCircle $isHover={isHover} $value={amount} $minBetAmount={minBetAmount}>
        {amount}
      </ChipCircle>
    </ChipWrapper>
  )
}

export const HoverChip: React.FC<ChipProps> = props => <Chip {...props} isHover />
export const PlacedChip: React.FC<ChipProps> = props => <Chip {...props} />
