import * as Sentry from '@sentry/react'
import { useConnectWallet, useLinkAccount, useLogin, useLogout } from '@privy-io/react-auth'

import { usePrivyService } from '../usePrivyService'
import { useActiveWallet } from './useActiveWallet'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { usePostLog } from '@/lib/posthog/logging'
import { useSupportModal } from '@/hooks/useSupportModal'

export const useAuthWallet = () => {
  const { activeWallet, isWalletAuthed, user, sessionVerifyState } = useActiveWallet()
  const { privyFareLogout } = usePrivyService()
  const shouldPromptLoginRef = useRef(false)
  const [shouldPromptLogin, setShouldPromptLogin] = useState(false)
  const { postlog } = usePostLog()
  const { showSupportModal } = useSupportModal()
  const { login: privyAppLogin } = useLogin({
    onComplete(...privyLoginParams) {
      postlog(`privy_login_complete`, {
        loglevel: 'success',
        eventName: `privy_login_complete`,
        privyLoginParams,
      })

      if (privyLoginParams[0].user.wallet) {
        Sentry.setUser({
          id: privyLoginParams[0].user.wallet.address,
        })
      }
    },
    onError(privyError) {
      postlog(`privy_login_error`, {
        loglevel: 'error',
        eventName: `privy_login_error`,
        privyError,
      })
      showSupportModal()
    },
  })
  const { connectWallet } = useConnectWallet({
    onSuccess: wallet => {
      // Any logic you'd like to execute after a user successfully connects their wallet
      postlog(`privy_connected_external_wallet`, {
        loglevel: 'success',
        eventName: `privy_connected_external_wallet`,
        wallet,
      })
    },
    onError: error => {
      // console.log('connectWallet Error', error)
      // Any logic you'd like to execute after a user exits the connection flow or there is an error
      postlog(`privy_connect_wallet_error`, {
        loglevel: 'error',
        eventName: `privy_connect_wallet_error`,
        error,
      })

      showSupportModal()
    },
  })
  const { logout: privyAppLogout } = useLogout({
    onSuccess() {
      // console.log('Logged out of privy')
      if (shouldPromptLoginRef.current) {
        shouldPromptLoginRef.current = false
        setShouldPromptLogin(true)
      }
    },
  })

  const { linkWallet } = useLinkAccount({
    onSuccess: ({ user, linkMethod, linkedAccount }) => {
      // console.log(user, linkMethod, linkedAccount)
      // Any logic you'd like to execute if the user successfully links an account while this
      // component is mounted
      postlog(`privy_linked_account`, {
        loglevel: 'success',
        eventName: `privy_linked_account`,
        user,
        linkMethod,
        linkedAccount,
      })
    },
    onError: (error, details) => {
      // console.log(error, details)
      if (details.linkMethod === 'siwe' && error === 'linked_to_another_user') {
        shouldPromptLoginRef.current = true
        privyAppLogout()
      }
      // Any logic you'd like to execute after a user exits the link flow or there is an error
      postlog(`privy_link_account_error`, {
        loglevel: 'error',
        eventName: `privy_link_account_error`,
        error,
        details,
      })

      showSupportModal()
    },
  })

  useEffect(() => {
    if (shouldPromptLogin) {
      setShouldPromptLogin(false)
      privyAppLogin()
    }
  }, [shouldPromptLogin])

  const isConnectedUserALinkedAccount = useMemo(() => {
    if (!user || !activeWallet) return false
    return activeWallet.linked
    // return Boolean(
    //   user.linkedAccounts.find(
    //     linkedAccount =>
    //       ((linkedAccount as any).address || '').toLowerCase() ===
    //       activeWallet.address.toLowerCase()
    //   )
    // )
  }, [user, activeWallet])

  const logout = useCallback(async () => {
    await privyAppLogout()
    await privyFareLogout()
    useAppChainConfigStore.getState().setSessionVerifyState('pending')
  }, [privyAppLogout])

  const linkWalletToUser = useCallback(async () => {
    try {
      if (activeWallet) {
        try {
          linkWallet()
        } catch (err) {
          console.error(err)
          showSupportModal()
        }
      }
    } catch (err) {
      console.error(err)
      showSupportModal()
    }
  }, [activeWallet, privyAppLogin, connectWallet, logout, isConnectedUserALinkedAccount])

  const linkOrLoginWallet = useCallback(async () => {
    try {
      if (activeWallet && isConnectedUserALinkedAccount) {
        try {
          // await activeWallet.loginOrLink()
          shouldPromptLoginRef.current = true
          privyAppLogout()
        } catch (err) {
          console.error(err)
        }
      } else if (activeWallet && !isConnectedUserALinkedAccount) {
        shouldPromptLoginRef.current = true
        privyAppLogout()
      } else if (!activeWallet && !isConnectedUserALinkedAccount && user) {
        shouldPromptLoginRef.current = true
        privyAppLogout()
      } else {
        privyAppLogin()
      }
    } catch (err) {
      console.error(err)
    }
  }, [activeWallet, privyAppLogin, connectWallet, logout, isConnectedUserALinkedAccount])

  return {
    logout,
    linkOrLoginWallet,
    linkWalletToUser,
    isWalletAuthed,
    sessionVerifyState,
  }
}
