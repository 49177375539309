import { default as ParticlesElement, initParticlesEngine } from '@tsparticles/react'
import { loadSlim } from '@tsparticles/slim'
import { MenuParticlesWrapper } from './style'

interface IMenuParticlesProps {
  particleColors: string | string[]
}

export const MenuParticles = ({ particleColors }: IMenuParticlesProps) => {
  const [init, setInit] = useState(false)

  useEffect(() => {
    initParticlesEngine(async engine => {
      await loadSlim(engine)
    })
      .then(() => {
        setInit(true)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const options = useMemo(() => {
    return {
      fpsLimit: 120,
      pauseOnBlur: true,
      pauseOnOutsideViewport: true,
      fullScreen: {
        enable: false,
        zIndex: 1,
      },
      particles: {
        color: {
          value: particleColors === typeof 'string' ? [particleColors] : particleColors,
        },
        collisions: {
          enable: true,
        },
        move: {
          enable: true,
          random: false,
          speed: 0.25,
          direction: 'right',
        },
        number: {
          value: 100,
        },
        opacity: {
          value: 1,
        },
        shape: {
          type: 'circle',
        },
        size: {
          value: { min: 0.5, max: 0.75 },
        },
      },
    } as any
  }, [particleColors])

  return (
    <MenuParticlesWrapper>
      {init && <ParticlesElement id='menuParticles' options={options} />}
    </MenuParticlesWrapper>
  )
}
