import React from 'react'
import { Canvas } from '@react-three/fiber'
import { styled } from 'styled-components'
import { BORDER_COLORS, BREAKPOINTS, COMPONENTS } from '@/design'
import { RouletteScene } from './RouletteScene'
import { SBorderAnimator } from '@/components/Canvases/shared/BorderFlash'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import { SVolumeSliderWrapper, VolumeSlider } from '@/components/shared/SoundSystem/VolumeSlider'
import { DeltaNumbersDisplay } from '@/components/DeltaNumbersDisplay'
import { AppGameName } from '@/chains/types'
import { useRouletteGameStore } from '@/store/useRouletteGameStore'
import { AnimatedResultText } from './AnimatedResultText'
import RouletteDrawer from './RouletteDrawer'
import { useIsBreakpoint, useIsBreakpointLandscape } from '@/hooks/common/useIsBreakpoint'
import ShareWinningsIconButton from '@/components/shared/ShareWinningsIconButton'

const SRouletteCanvas = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid ${BORDER_COLORS.one};
  backdrop-filter: blur(2px);
  grid-column: 1 / span 5;
  position: relative;
  overflow: hidden;
  -webkit-backdrop-filter: blur(2px);
  -moz-backdrop-filter: blur(2px);
  -ms-backdrop-filter: blur(2px);

  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    height: ${COMPONENTS.mobileGameView}px;
    backdrop-filter: blur(2px);

    /* Vendor prefixes */
    -webkit-backdrop-filter: blur(2px); /* Safari and Chrome */
    -moz-backdrop-filter: blur(2px); /* Firefox */
    -ms-backdrop-filter: blur(2px); /* Edge */
  }
`

export const RouletteCanvas: React.FC = () => {
  const isShowingOutcome = useGameOutcomeStore(state => state.isShowingOutcome)
  const didPlayerWin = useGameOutcomeStore(state => state.didPlayerWin)
  const intensity = useGameOutcomeStore(state => state.intensity)
  const { result, gameState, isDrawerOpen } = useRouletteGameStore()
  const isMobileScreen = useIsBreakpoint('sm')
  const isLandscape = useIsBreakpointLandscape('sm')

  if (gameState === 'IDLE' && isMobileScreen && isLandscape) {
    return null
  }

  return (
    <SRouletteCanvas>
      <DeltaNumbersDisplay gameName={AppGameName.Roulette} />
      <SBorderAnimator active={isShowingOutcome} didWin={didPlayerWin} intensity={intensity}>
        <Canvas
          camera={{ position: [0, -3, 3], fov: 60 }}
          shadows
          dpr={[1, 2]}
          gl={{ antialias: true, stencil: false, preserveDrawingBuffer: false, alpha: true }}
        >
          <fog attach='fog' args={['#000000', 5, 80]} />
          <RouletteScene
            isDrawerOpen={isDrawerOpen}
            onResultShown={() => {
              // Handle any additional logic needed when result is shown
            }}
          />
        </Canvas>
        <AnimatedResultText result={result ?? 0} isVisible={gameState === 'RESULT_SHOWN'} />
        {!isLandscape && !isMobileScreen && <RouletteDrawer />}
        <SVolumeSliderWrapper>
          <VolumeSlider />
        </SVolumeSliderWrapper>
        {didPlayerWin && <ShareWinningsIconButton />}
      </SBorderAnimator>
    </SRouletteCanvas>
  )
}
export default RouletteCanvas
