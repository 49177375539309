import React from 'react'
import { BORDER_COLORS, BREAKPOINTS } from '@/design'
import { Form } from '@/components/Forms/style'
import { phoneBP } from '@/design/phoneSizes'

export const cssContainer = css`
  border: 1px solid ${BORDER_COLORS.one};
  backdrop-filter: blur(2px);

  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    height: 190px;
    overflow: scroll;
  }

  @media ${phoneBP.xs} and (max-height: 605px) {
    height: 90px;
  }

  @media ${phoneBP.lg} and (min-height: 800px) {
    height: 290px;
  }

  @media (max-width: ${BREAKPOINTS.sm}px) and (orientation: landscape) {
    height: 100%;
    overflow-x: hidden;
  }
`

export const SForm = styled(Form).attrs({ as: 'form' })`
  ${cssContainer};
`

interface FormLayoutProps {
  children: React.ReactNode
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void
}

export const FormLayout = ({ children, onSubmit }: FormLayoutProps) => {
  return (
    <SForm
      onSubmit={e => {
        e.preventDefault()
        onSubmit?.(e)
      }}
    >
      {children}
    </SForm>
  )
}
