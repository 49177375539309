import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useAppChainConfig } from '@/hooks/useAppChainConfig'

export function getCookieDomainFromHttpUrl(httpUrl: string): string {
  const urlObj = new URL(httpUrl)
  const hostname = urlObj.hostname // e.g. 'localhost', 'api.fare.dev', 'api.fare.wtf', or 'api.fareplay.io'

  // For local dev, you typically omit the domain so the browser sets it to 'localhost'
  if (hostname === 'localhost' || hostname.startsWith('127.0.0.1')) {
    return 'localhost' // no domain => browser uses localhost
  }

  // If your subdomain is 'api.fare.dev', you want a cookie domain of '.fare.dev'
  if (hostname.endsWith('.fare.dev')) return '.fare.dev'
  if (hostname.endsWith('.fare.wtf')) return '.fare.wtf'
  if (hostname.endsWith('.fareplay.io')) return '.fareplay.io'

  // Fallback if you have other environments or custom logic
  // For instance, you might return '.' + the last two segments
  return hostname
}

export function ReferralListener() {
  const [searchParams] = useSearchParams()
  const { appChainConfig } = useAppChainConfig()

  useEffect(() => {
    const referralCode = searchParams.get('ref')
    if (referralCode) {
      // ╭─────────────────────────────────────────────────────────╮
      // │ You might only set it if it doesn't exist yet, or       │
      // │ overwrite every time you see a new one:                 │
      // ╰─────────────────────────────────────────────────────────╯
      Cookies.set('referralCode', referralCode, {
        domain: getCookieDomainFromHttpUrl(appChainConfig.httpUrl),
        expires: 90,
      })
    }
  }, [appChainConfig.httpUrl, searchParams])

  return null
}
