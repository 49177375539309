import { useState, useCallback } from 'react'

export const useShareWinningsModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleShareWinningsClick = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  return {
    isModalOpen,
    handleShareWinningsClick,
    handleCloseModal,
  }
}
