// import { SVGS } from '@/assets'
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  // COLORS,
  FARE_COLORS,
  // SPACING,
  TEXT_COLORS,
} from '@/design'
// import { noUserSelect } from '@/style'
// import { deviceBP } from '@/design/breakpoints'
import { DepositWrapper, SettingsSubtitle, TitleWrapper } from './style'
import { addAppNoti } from '@/store/useNotiStore'
import { Button, ButtonEnum } from '@/components/shared/Button'
// import { DeathmatchTable, DeathmatchTableWrapper } from '../DeathmatchPage/style'
// import { LeaderBoardTable, LeaderboardTableWrapper } from '../LeaderboardPage/style'
// import LeaderboardTabs from '../PointsPage/LeaderboardTabs'
// import useUserData from '@/hooks/useUserData'
import { useUserApi } from '@/lib/useUserApi'
import useUserDataStore from '@/store/useUserDataStore'
// import { useAuthedActiveWallet } from '@/lib/privy/hooks'

const CopyInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`

const ReferralLinkInput = styled.input`
  background: ${BACKGROUND_COLORS.one};
  border: 1px solid ${BORDER_COLORS.one};
  color: ${TEXT_COLORS.one};
  border-radius: 6px;
  padding-inline: 8px;
  font-size: 13px;
  flex: 1;
  height: 40px;

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &:focus {
    outline: none;
    border: 1px solid ${FARE_COLORS.aqua};
  }

  @media (min-width: 768px) {
    width: auto;
  }
`

const CopyButton = styled(Button)`
  width: 100px;
  background-color: ${FARE_COLORS.aqua}50;
  color: white;
  font-weight: bold;
  height: 40px;
  padding-top: 10px;
`

const SectionWrapper = styled(DepositWrapper)`
  border-radius: 6px;
  border: 1px solid ${BORDER_COLORS.one};
  width: 100%;
  background: ${BACKGROUND_COLORS.two};
  overflow: hidden;
  text-align: justify;
  margin-block: 20px;

  p {
    color: ${TEXT_COLORS.two};
  }
`

const TitleDivider = styled.div`
  border-top: 3px solid ${FARE_COLORS.aqua}50;
  padding: 10px !important;
`

const ReferralListHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  width: calc(100% + 20px);
  margin-left: -10px;
  border-bottom: 1px solid ${BORDER_COLORS.one};
  padding-bottom: 20px;

  p {
    flex: 1;
    margin-inline: 10px;
  }

  p:nth-child(1) {
    text-align: left;
  }

  p:nth-child(2) {
    text-align: center;
  }

  p:nth-child(3) {
    text-align: right;
  }
`

const ReferralList = styled.div`
  padding-block: 20px;
  border-bottom: 1px solid ${BORDER_COLORS.one};
  color: ${TEXT_COLORS.one};
  width: calc(100% + 20px);
  margin-left: -10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    margin-block: 2px;
    margin-inline: 10px;
    flex: 1;
  }

  p:nth-child(1) {
    text-align: left;
  }

  p:nth-child(2) {
    text-align: center;
  }

  p:nth-child(3) {
    text-align: right;
  }

  &:first-child {
    padding-top: 0px;
  }

  &:last-child {
    border: none;
    padding-bottom: 10px;
  }
`

const EmptyCell = styled.p`
  padding-top: 0 !important;
  padding-bottom: 20px !important;
`

export const ReferralsTab = () => {
  const userApi = useUserApi()

  const { hostname, port, protocol } = window.location

  const {
    referralCode,
    referredUsers,
    totalAssistPoints,
    setReferralCode,
    setReferredUsers,
    setTotalAssistPoints,
  } = useUserDataStore()

  const referralUrl = `${protocol}//${hostname}${port === '3000' ? `:3000` : ''}/?ref=${referralCode}`

  useEffect(() => {
    ;(async () => {
      try {
        const { referredUsers, totalAssistPoints, referralCode } = await userApi.getUserReferrals()
        setReferralCode(referralCode)
        setReferredUsers(referredUsers)
        setTotalAssistPoints(totalAssistPoints)
      } catch (err) {
        addAppNoti({
          type: 'error',
          msg: `Failed to fetch referral data: ${String(err)}`,
        })
      }
    })()
  }, [setReferralCode, setReferredUsers, setTotalAssistPoints, userApi])

  const copyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(referralUrl)
      addAppNoti({
        type: 'success',
        msg: 'Copied to clipboard',
      })
    } catch (err) {
      addAppNoti({
        type: 'error',
        msg: 'Failed to copy',
      })
    }
  }, [referralUrl])

  return (
    <>
      <SettingsSubtitle>
        Start earning assist points, and refer your friends! Share your link below to earn points
        whenever they place a bet
      </SettingsSubtitle>

      <CopyInputWrapper>
        <ReferralLinkInput value={referralUrl} disabled />
        <CopyButton
          type='button'
          buttonType={ButtonEnum.CONNECT_WALLET}
          onClick={copyToClipboard}
          disabled={false}
        >
          COPY
        </CopyButton>
      </CopyInputWrapper>

      <SectionWrapper>
        <TitleWrapper style={{ paddingBlock: '16px' }}>Total Assist Points</TitleWrapper>
        <TitleDivider />

        <EmptyCell>{totalAssistPoints}</EmptyCell>
      </SectionWrapper>

      <SectionWrapper>
        <TitleWrapper style={{ paddingBlock: '16px' }}>Your Referrals</TitleWrapper>
        <TitleDivider />
        {referredUsers && referredUsers.length > 0 ?
          <div style={{ paddingTop: '0px' }}>
            <ReferralListHeader>
              <p>username</p>
              <p>public address</p>
              <p>assist points</p>
            </ReferralListHeader>
            {referredUsers.map(referral => (
              <ReferralList key={referral.publicAddress}>
                <p>{referral.username || 'Unnamed'}</p>
                <p>{referral.publicAddress}</p>
                <p>{referral.assistPoints} Assist Points</p>
              </ReferralList>
            ))}
          </div>
        : <EmptyCell>You have not referred anyone yet.</EmptyCell>}
      </SectionWrapper>
    </>
  )
}
