import { PerspectiveCamera } from '@react-three/drei'
import { Vector3, type PerspectiveCamera as PerspectiveCameraImpl } from 'three'
import { type PerspectiveCameraProps } from '@react-three/fiber'

export const DiceCamera = (props: PerspectiveCameraProps) => {
  const cameraRef = useRef<PerspectiveCameraImpl>(null!)
  const vec3Zero = useMemo(() => new Vector3(0, 0, 0), [])
  const cameraPos = useMemo(() => new Vector3(0, 1.25, 5.25), [])

  useEffect(() => {
    if (cameraRef.current) {
      cameraRef.current.lookAt(vec3Zero)
    }
  }, [])

  return (
    <>
      <PerspectiveCamera makeDefault ref={cameraRef} position={cameraPos} fov={60} {...props} />
    </>
  )
}
