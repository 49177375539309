import { Canvas } from '@react-three/fiber'
import { RPSScene } from './RPSScene'
import { AnimatePresence } from 'framer-motion'
import { DeltaNumbersDisplay } from '@/components/DeltaNumbersDisplay'
import { AppGameName } from '@/chains/types'
import { SBorderAnimator } from '../shared/BorderFlash'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import { SVolumeSliderWrapper, VolumeSlider } from '@/components/shared/SoundSystem/VolumeSlider'
import { useSound } from '@/components/shared/SoundSystem/SoundContext'
import { ResizingWrapper, SCanvas } from '../styles'
import { useCanvasResize } from '@/hooks/useCanvasResize'
import { type ICanvas } from '../shared/mountedInterface'
import { LoadingChip } from '@/components/shared/LoadingChip'

import ShareWinningsIconButton from '@/components/shared/ShareWinningsIconButton'

const RPSCanvas = ({ isMounted, setIsMounted }: ICanvas) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const { canvasSize, isResizing } = useCanvasResize(containerRef, canvasRef)

  const isShowingOutcome = useGameOutcomeStore(state => state.isShowingOutcome)
  const didPlayerWin = useGameOutcomeStore(state => state.didPlayerWin)
  const intensity = useGameOutcomeStore(state => state.intensity)
  /* SFX */
  const { loadSound, playSound } = useSound()

  return (
    <SCanvas ref={containerRef} style={{ opacity: isMounted ? 1 : 0 }}>
      <SBorderAnimator active={isShowingOutcome} didWin={didPlayerWin} intensity={intensity}>
        <DeltaNumbersDisplay gameName={AppGameName.RPS} />
        <AnimatePresence>
          <Canvas
            ref={canvasRef}
            key={`${canvasSize.width}-${canvasSize.height}`}
            shadows
            dpr={[1, 2]}
            gl={{ antialias: true, stencil: false, preserveDrawingBuffer: false, alpha: true }}
            onCreated={_state => {
              setTimeout(() => {
                setIsMounted(true)
              }, 800)
            }}
          >
            <fog attach='fog' args={['black', 5, 15]} />
            <RPSScene loadSound={loadSound} playSound={playSound} />
          </Canvas>
        </AnimatePresence>
        <SVolumeSliderWrapper>
          <VolumeSlider />
        </SVolumeSliderWrapper>
        {didPlayerWin && <ShareWinningsIconButton />}
      </SBorderAnimator>
      {isResizing && (
        <ResizingWrapper>
          <LoadingChip width={56} height={56} />
        </ResizingWrapper>
      )}
    </SCanvas>
  )
}

export default RPSCanvas
