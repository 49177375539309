import { useFrame } from '@react-three/fiber'
import numeral from 'numeral'
import { Clock, Color, type MeshPhysicalMaterial } from 'three'
import { MathUtils } from 'three'
import { easeOutSine } from '@/utils/3d'
import { DiceNumber, type DiceNumberProps } from './DiceNumber'
import { useDiceGameState } from '@/store/useGameStateStore'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import { entryEvent } from '@/events/entryEvent'
import { DiceModelNew, type DiceModelRef } from './DiceModelNew'
import { gameTypeHelperFunctionsMap } from '@/lib/crypto'
import { useSound } from '@/components/shared/SoundSystem/SoundContext'
import MultiDiceLandAudio from '@//assets/audio/mouse-over-slider.wav'
// import MultiDiceLoseAudio from '@//assets/audio/Error 07.wav'
import MultiDiceStartAudio from '@//assets/audio/Device 7 Start.wav'
import MultiDiceCoinsAudio from '@//assets/audio/coins/Coins 11.wav'
import MultiDiceWinAudio from '@//assets/audio/bombs-win.wav'
import MultiDiceLoseAudio from '@//assets/audio/Device 7 Stop.wav'
import { gameProxy } from '@/store/proxy/gameProxy'

export interface IMultiDice {
  amount: number
  multiDiceState: 'initial' | 'start' | 'resolve'
  resetDice: () => void
}

const { lerp } = MathUtils

const DICE_START_Y = 0.2
const DICE_END_Y = 3
const ZINK_DELAY = 0.2
const ZINK_TO_TOP_TIME = 0.6
const NEXT_BATCH_DELAY = 1

export const MultiDice = ({ amount, multiDiceState, resetDice }: IMultiDice) => {
  // Cached textures
  const setBorderActive = useGameOutcomeStore(state => state.setIsShowingOutcome)
  const setPlayerWon = useGameOutcomeStore(state => state.setDidPlayerWin)
  const setWinIntensity = useGameOutcomeStore(state => state.setIntensity)
  const isShowingOutcome = useGameOutcomeStore(state => state.isShowingOutcome)

  /* Refs */
  const diceObjectRefs = useRef<Array<DiceObjectRefProps>>([])
  const textRefs = useRef<Array<DiceNumberProps>>([])
  const isResolved = useRef<Array<boolean>>(Array(amount).fill(false))
  const animClock = useRef<Clock>(new Clock(false))
  const currentBatchIdx = useRef<number>(0)
  const selectedSide = useRef(50.5)
  const resultSides = useRef<number[]>([])
  const resultDeltaAmounts = useRef<number[]>([])
  const submittedAmount = useRef(0)
  const submittedCount = useRef(0)
  const playedCount = useRef(0)
  const winsToBreakeven = useRef(0)

  /* Memos */
  const winEmissionColor = useMemo(() => new Color(0.1, 1, 0.1), [])
  const loseEmissionColor = useMemo(() => new Color(1, 0.1, 0.1), [])
  const whiteColor = useMemo(() => new Color(1, 1, 1), [])

  /* Sound */
  const { loadSound, playSound } = useSound()

  useEffect(() => {
    loadSound('multiDiceLand', MultiDiceLandAudio)
    // loadSound('multiDiceLose', MultiDiceLoseAudio)
    loadSound('multiDiceStart', MultiDiceStartAudio)
    loadSound('multiDiceCoins', MultiDiceCoinsAudio)
    loadSound('multiDiceWin', MultiDiceWinAudio)
    loadSound('multiDiceLose', MultiDiceLoseAudio)

    // return () => {
    //   // can unload sounds here if we want
    // }
  }, [loadSound])

  const MIN_PITCH = 0.8
  const MAX_PITCH = 1.8
  const PITCH_INCREMENT = 0.05
  const winsThisRound = useRef(0)

  interface DiceObjectRefProps {
    diceGroupRef: THREE.Group | null
    setWinState: () => void
    setLoseState: () => void
    resetState: () => void
  }

  const DiceModels = useMemo(() => {
    const models: any[] = []

    new Array(amount).fill(null).forEach((_val, idx) => {
      const columnIdx = idx % 5
      const offset = 1
      const xPos = (3 - (5 - columnIdx)) * offset
      const yPos = DICE_END_Y
      const zPos = 1

      const diceObjectRef = (ref: DiceModelRef) => {
        if (ref) {
          diceObjectRefs.current[idx] = {
            diceGroupRef: ref.diceGroupRef,
            setWinState: ref.setWinState,
            setLoseState: ref.setLoseState,
            resetState: ref.resetState,
          }
        }
      }

      const textRef = (ref: any) => {
        textRefs.current[idx] = ref
      }

      models.push(
        <group>
          <DiceModelNew
            key={idx}
            ref={diceObjectRef}
            scaleOverride={0.125}
            groupProps={{ position: [xPos, yPos, zPos] }}
            boxGeometryProps={{ args: [0.33, 0.33, 0.33, 10, 10, 10] }}
          />
          <DiceNumber ref={textRef as any} position-y={0.7} fontSize={0.23} visible={false} />
        </group>
      )
    })

    return models
  }, [amount])

  useEffect(() => {
    // if (multiDiceState === 'start') {
    if (multiDiceState === 'initial') {
      animClock.current.start()
      winsThisRound.current = 0
      playSound('multiDiceStart', 0.2, 0.75)
    }

    if (multiDiceState === 'resolve') {
      animClock.current.start()
      currentBatchIdx.current = 1
      selectedSide.current = Number(useDiceGameState.getState().submittedEntry?.side) / 100
      resultSides.current = useDiceGameState.getState().results?.resultSides || []
      resultDeltaAmounts.current = useDiceGameState.getState().results?.deltaAmounts || []
      submittedAmount.current = useDiceGameState.getState().submittedEntry?.entryAmount || 0
      submittedCount.current = useDiceGameState.getState().submittedEntry?.entryCount || 0
      playedCount.current = useDiceGameState.getState().results?.playedCount || 0
    }
  }, [multiDiceState, playSound])

  const getNextBatchIdx = () => {
    const canGoNext = currentBatchIdx.current * 5 - diceObjectRefs.current.length < 0
    if (canGoNext) {
      currentBatchIdx.current++
      return true
    } else {
      return false
    }
  }

  const factorial = (n: number) => {
    if (n === 0 || n === 1) return 1
    let result = 1
    for (let i = 2; i <= n; i++) {
      result *= i
    }
    return result
  }

  const binomialCoefficient = (n: number, k: number) => {
    return factorial(n) / (factorial(k) * factorial(n - k))
  }

  const binomialProbability = (n: number, k: number, p: number) => {
    return binomialCoefficient(n, k) * Math.pow(p, k) * Math.pow(1 - p, n - k)
  }

  const cumulativeBinomialProbability = (n: number, k: number, p: number): number => {
    let cumulativeProbability = 0
    for (let i = k; i <= n; i++) {
      cumulativeProbability += binomialProbability(n, i, p)
    }
    return cumulativeProbability
  }

  const { getMultiplierWithPPV } = useMemo(() => gameTypeHelperFunctionsMap.dice, [])

  const getCalculatedMultiplier = useCallback(
    (side: number) => getMultiplierWithPPV(side * 100),
    [getMultiplierWithPPV]
  )

  const calculateOdds = (resultSides: number[], diceIdx: number, selectedSide: number): number => {
    const slicedArray = resultSides.slice(0, diceIdx + 1)

    const wins = slicedArray.filter(side => side > selectedSide * 100).length

    if (diceIdx === 0) {
      winsToBreakeven.current = resultSides.length / getCalculatedMultiplier(selectedSide)
    }

    const isWinningOverall = wins >= winsToBreakeven.current
    setPlayerWon(isWinningOverall)
    if (!isWinningOverall) return 0

    const individualOdds = 1 - selectedSide / 100
    const combinedOdds = cumulativeBinomialProbability(resultSides.length, wins, individualOdds)

    return combinedOdds
  }
  const determineColor = (
    selectedSide: number,
    resolveSide: number,
    diceObjectRef: DiceObjectRefProps,
    diceIdx: number
  ) => {
    const didWin = resolveSide > selectedSide

    if (didWin) {
      diceObjectRef.setWinState()
      winsThisRound.current++
      const pitch = Math.min(MIN_PITCH + (winsThisRound.current - 1) * PITCH_INCREMENT, MAX_PITCH)
      playSound('multiDiceLand', 0.3, pitch)
      playSound('multiDiceCoins', 0.3, pitch)
    } else {
      diceObjectRef.setLoseState()
      playSound('multiDiceLose', 0.3, 1.5 + Math.random() * 0.1)
    }

    if (!isShowingOutcome) setBorderActive(true)
    const odds = calculateOdds(resultSides.current, diceIdx, selectedSide)

    if (odds <= 0.13) setWinIntensity(5)
    else if (odds <= 0.3) setWinIntensity(4)
    else if (odds <= 0.5) setWinIntensity(3)
    else if (odds <= 0.65) setWinIntensity(2)
    else setWinIntensity(1)

    const textRef = textRefs.current[diceIdx]
    textRef.color = didWin ? winEmissionColor : loseEmissionColor
    ;(textRef.material as MeshPhysicalMaterial).emissive =
      didWin ? winEmissionColor : loseEmissionColor
    ;(textRef.material as MeshPhysicalMaterial).emissiveIntensity = didWin ? 2.3 : 5.9
    textRef.visible = true
  }

  const displayRandomNum = (diceIdx: number, randomNum: number) => {
    const textRef = textRefs.current[diceIdx]
    const diceObject = diceObjectRefs.current[diceIdx]?.diceGroupRef

    if (diceObject && textRef) {
      textRef.visible = true
      textRef.text = numeral(String(randomNum)).format('00.00')
      ;(textRef.position as any).set(diceObject.position.x, 0.6, diceObject.position.z)
    }
  }

  const hidePreviousDice = () => {
    diceObjectRefs.current
      .slice((currentBatchIdx.current - 1) * 5, currentBatchIdx.current * 5)
      .forEach(diceRef => {
        if (diceRef.diceGroupRef) {
          diceRef.diceGroupRef.visible = false
        }
        diceRef.resetState()
      })

    textRefs.current
      .slice((currentBatchIdx.current - 1) * 5, currentBatchIdx.current * 5)
      .forEach(textRef => {
        textRef.visible = false
        textRef.color = whiteColor
        ;(textRef.material as MeshPhysicalMaterial).emissive = whiteColor
        ;(textRef.material as MeshPhysicalMaterial).emissiveIntensity = 0
      })
  }

  const zonkToBottom = (elapsedTime: number) => {
    const diceRefs = diceObjectRefs.current.slice(
      (currentBatchIdx.current - 1) * 5,
      currentBatchIdx.current * 5
    )
    let allDiceResolved = true

    diceRefs.forEach((diceObject: DiceObjectRefProps, ogIdx) => {
      const idx = ogIdx + (currentBatchIdx.current - 1) * 5
      const delay = ogIdx * ZINK_DELAY
      let t = (elapsedTime - delay) / ZINK_TO_TOP_TIME

      if (elapsedTime >= delay && t <= 1 && diceObject.diceGroupRef) {
        const shapingT = easeOutSine(t)
        const posY = lerp(DICE_END_Y, DICE_START_Y, shapingT)
        const rotX = lerp(Math.PI * 6, 0, shapingT)
        const rotZ = lerp(Math.PI * 6, 0, shapingT)

        diceObject.diceGroupRef.position.y = posY
        diceObject.diceGroupRef.rotation.x = rotX
        diceObject.diceGroupRef.rotation.z = rotZ
      }

      if (t >= 1) {
        t = 1
      }

      if (t === 1 && !isResolved.current[idx]) {
        const randomNum = Number(resultSides.current[idx]) / 100
        determineColor(selectedSide.current, randomNum, diceObjectRefs.current[idx], idx)
        displayRandomNum(idx, randomNum)
        isResolved.current[idx] = true
        // @NOTE: Handles the delta amount text to render according to the stopLoss stopGain cases
        if (playedCount.current > idx) {
          const reward = resultDeltaAmounts.current[idx]
          const amount = submittedAmount.current
          const count = submittedCount.current

          if (reward !== undefined && amount !== undefined && count !== undefined && count !== 0) {
            const deltaAmount = Number(reward)
            if (gameProxy.pathGameName !== 'dice') return
            entryEvent.pub('entryFinished', {
              deltaAmount: deltaAmount,
            })
          } else {
            console.error('One of the components of deltaAmount is undefined or count is zero', {
              reward,
              amount,
              count,
            })
          }
        }
      }

      if (t < 1 || !isResolved.current[idx]) {
        allDiceResolved = false
      }
    })

    const batchHasFinished =
      (elapsedTime + diceRefs.length * -ZINK_DELAY - NEXT_BATCH_DELAY) / ZINK_TO_TOP_TIME > 1

    if (batchHasFinished) {
      hidePreviousDice()
      const hasNext = getNextBatchIdx()
      animClock.current.stop()

      if (!hasNext && allDiceResolved) {
        // This is the last batch and all dice have finished animating
        const totalWinnings = resultDeltaAmounts.current.reduce(
          (sum, reward) => sum + Number(reward),
          0
        )
        const initialBet = submittedAmount.current
        const netWinnings = totalWinnings - initialBet

        if (netWinnings > 0) {
          playSound('multiDiceWin', 0.4, 1)
          playSound('multiDiceCoins', 0.3, 1)
        } else {
          playSound('multiDiceLose', 0.5, 1)
        }

        if (gameProxy.pathGameName !== 'dice') return
        resetDice()
        entryEvent.pub('gameFinished')
        entryEvent.pub('updateBalance')
      } else if (hasNext) {
        animClock.current.start()
      }
    }
  }

  useFrame(({ clock }) => {
    if (multiDiceState === 'resolve' && animClock.current.running && currentBatchIdx.current > 0) {
      zonkToBottom(animClock.current.getElapsedTime())
    }
  })

  return <>{DiceModels}</>
}
