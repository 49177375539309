import * as THREE from 'three'
import React, { useRef, useEffect, useMemo } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import type { GLTF } from 'three-stdlib'
import { type Group } from 'three'
import { useFrame, type GroupProps } from '@react-three/fiber'

type GLTFResult = GLTF & {
  nodes: {
    Mesh009: THREE.Mesh
    Mesh009_1: THREE.Mesh
    Mesh009_2: THREE.Mesh
    Mesh009_3: THREE.Mesh
    Mesh009_4: THREE.Mesh
    Mesh009_5: THREE.Mesh
    Mesh009_6: THREE.Mesh
    NumbersAll: THREE.Mesh
    ZeroTrigger: THREE.Mesh
  }
  materials: {
    black: THREE.MeshStandardMaterial
    red: THREE.MeshStandardMaterial
    green: THREE.MeshStandardMaterial
    'gold.001': THREE.MeshStandardMaterial
    MetalMain: THREE.MeshStandardMaterial
    'wood.light': THREE.MeshStandardMaterial
    AccentLights: THREE.MeshStandardMaterial
    Numbers: THREE.MeshStandardMaterial
  }
  animations: THREE.AnimationClip[]
}

const MODEL_PATH = '/glb/RouletteWheelFinal.glb'

interface RouletteWheelProps extends GroupProps {
  onRotationUpdate?: (rotation: number) => void
  showTrigger?: boolean
}

export function RouletteWheel({
  onRotationUpdate,
  showTrigger = true,
  ...props
}: RouletteWheelProps) {
  const group = useRef<Group>(null)
  const { nodes, materials, animations } = useGLTF(MODEL_PATH) as GLTFResult
  const { actions } = useAnimations(animations, group)
  const triggerInitialPos = useRef<THREE.Vector3 | null>(null)
  const triggerWorldPos = useMemo(() => new THREE.Vector3(), [])

  // Start the steady spin animation and record initial trigger position
  useEffect(() => {
    const action = actions['SteadySpin.002' as keyof typeof actions]
    if (action) {
      action.reset().play()
      action.setLoop(THREE.LoopRepeat, Infinity)

      // Record initial trigger position
      const trigger = group.current?.getObjectByName('ZeroTrigger')
      if (trigger) {
        triggerInitialPos.current = new THREE.Vector3()
        trigger.getWorldPosition(triggerInitialPos.current)
      }
    }
  }, [actions])

  // Track rotation by monitoring trigger position
  useFrame(() => {
    if (group.current && triggerInitialPos.current) {
      const trigger = group.current.getObjectByName('ZeroTrigger')
      if (trigger) {
        // Get current trigger position
        trigger.getWorldPosition(triggerWorldPos)

        // Calculate angle from trigger position
        // Using atan2 to get angle in range [-π, π]
        const currentAngle = Math.atan2(triggerWorldPos.x, triggerWorldPos.z)
        const initialAngle = Math.atan2(triggerInitialPos.current.x, triggerInitialPos.current.z)

        // Get the relative rotation (how far we've turned from start)
        let relativeRotation = currentAngle - initialAngle

        // Normalize to [0, 2π]
        if (relativeRotation < 0) {
          relativeRotation += 2 * Math.PI
        }

        onRotationUpdate?.(relativeRotation)
      }
    }
  })

  // Early return if model isn't loaded
  if (!nodes || !materials) {
    console.warn('Model not loaded yet')
    return null
  }

  return (
    <group ref={group} {...props} dispose={null}>
      <group name='Scene'>
        <group name='roulette_low_poly_styled' scale={1.982}>
          {nodes.Mesh009 && (
            <mesh
              name='Mesh009'
              castShadow
              receiveShadow
              geometry={nodes.Mesh009.geometry}
              material={materials.black}
            />
          )}
          {nodes.Mesh009_1 && (
            <mesh
              name='Mesh009_1'
              castShadow
              receiveShadow
              geometry={nodes.Mesh009_1.geometry}
              material={materials.red}
            />
          )}
          {nodes.Mesh009_2 && (
            <mesh
              name='Mesh009_2'
              castShadow
              receiveShadow
              geometry={nodes.Mesh009_2.geometry}
              material={materials.green}
            />
          )}
          {nodes.Mesh009_3 && (
            <mesh
              name='Mesh009_3'
              castShadow
              receiveShadow
              geometry={nodes.Mesh009_3.geometry}
              material={materials['gold.001']}
            />
          )}
          {nodes.Mesh009_4 && (
            <mesh
              name='Mesh009_4'
              castShadow
              receiveShadow
              geometry={nodes.Mesh009_4.geometry}
              material={materials.MetalMain}
            />
          )}
          {nodes.Mesh009_5 && (
            <mesh
              name='Mesh009_5'
              castShadow
              receiveShadow
              geometry={nodes.Mesh009_5.geometry}
              material={materials['wood.light']}
            />
          )}
          {nodes.Mesh009_6 && (
            <mesh
              name='Mesh009_6'
              castShadow
              receiveShadow
              geometry={nodes.Mesh009_6.geometry}
              material={materials.AccentLights}
            />
          )}
          {nodes.NumbersAll && (
            <mesh
              name='NumbersAll'
              castShadow
              receiveShadow
              geometry={nodes.NumbersAll.geometry}
              material={materials.Numbers}
              position={[0.102, 0.366, -0.593]}
              rotation={[0.139, -0.168, 0.023]}
              scale={1.196}
            />
          )}
          {nodes.ZeroTrigger && (
            <mesh
              name='ZeroTrigger'
              castShadow
              receiveShadow
              geometry={nodes.ZeroTrigger.geometry}
              position={[0.023, 0.568, -0.888]}
              rotation={[0, -0.026, 0]}
              scale={0.505}
              material={
                new THREE.MeshBasicMaterial({
                  color: '#00ff00',
                  transparent: true,
                  opacity: showTrigger ? 0.5 : 0,
                  side: THREE.DoubleSide,
                  depthWrite: false,
                  depthTest: false,
                })
              }
            />
          )}
        </group>
      </group>
    </group>
  )
}

useGLTF.preload(MODEL_PATH)
