import numeral from 'numeral'
import { FareNumberInput } from '../../shared/Input/FareNumberInput'
import { BORDER_COLORS, TEXT_COLORS } from '@/design'
import { InputSlider } from '@/components/shared/Input/InputSlider'

const WithdrawalInput = styled(FareNumberInput)`
  background: #2c2c2e;
  border: 1px solid ${BORDER_COLORS.one};
  color: ${TEXT_COLORS.one};
  border-radius: 12px;
  height: 36px;
`

const CustomInputSlider = styled(InputSlider)`
  background: #ffffff20;
  border: 1px solid #6400cc10;
  border-radius: 12px;
  height: 10px;
  display: flex;
  align-items: center;
  position: relative;

  .thumb-0 {
    background: #7d00ff50;
    border: 1px solid #7d00ff;
  }

  :active {
    outline: none;
    border: 1px solid #7d00ff;
    background: #7d00ff;
  }
`

export const WithdrawInput = memo(function WithdrawInput({
  amount,
  setAmount,
  tokenIcon,
  tokenName,
  max,
  decimals = 2,
}: {
  amount: number
  max: number
  setAmount: any
  tokenIcon: string
  tokenName: string
  decimals: number
}) {
  return (
    <WithdrawalInput
      value={amount}
      onChange={event => {
        setAmount(numeral(event.target.value).value() as any)
      }}
      allowLeadingZeros={false}
      allowNegative={false}
      thousandSeparator=','
      decimalScale={decimals}
      hasInputSlider
      customInputSlider={CustomInputSlider}
      inputSuffix={<img src={tokenIcon} alt={tokenName} width={20} />}
      inputSliderProps={{
        value: amount,
        onChange: sliderValue => {
          setAmount(sliderValue)
        },
        min: 0,
        max,
        step: max / 100,
      }}
    />
  )
})
