import { type Variants, motion } from 'framer-motion'

import { BACKGROUND_COLORS, BORDER_COLORS } from '@/design'

export const SFarePopover = styled(motion.div)<{ $textColor?: string }>`
  position: absolute;
  color: ${props => props.$textColor || 'white'};
  top: -16px;
  border: 1px solid ${BORDER_COLORS.one};
  backdrop-filter: blur(1px);
  background: ${BACKGROUND_COLORS.two};
  padding: 2px 4px;
  font-size: 10px;
  line-height: 1;
  border-radius: 6px;
`

export const SLinkTitlePopover = styled(SFarePopover)`
  margin: auto;
  text-wrap: nowrap;
  top: 0;
  padding: 0;
  border: none;
  font-size: 9px;
`

export const farePopoverVariants: Variants = {
  initial: {
    y: 16,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 16,
    opacity: 0,
  },
}

export const popoverVariants = {
  initial: { opacity: 0, y: 0 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  exit: { opacity: 0, y: 16, transition: { duration: 0.5 } },
}
