import { utils } from 'ethers'
import { encodeFunctionData, parseAbi, parseUnits } from 'viem'
import {
  createSmartAccountClient,
  createBicoPaymasterClient,
  toBiconomyTokenPaymasterContext,
} from '@biconomy/sdk'

import { MAX_APPROVAL_AMOUNT } from '@/constants/crypto'
import { useAllowance } from '@/hooks/useAllowance'
import { useAuthedActiveWallet } from '@/lib/privy/hooks'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import useCurrencyStore from '@/store/useCurrencyStore'
import useSUContractStore, { initialSUContractResults } from '@/store/useSUContractStore'
import { useCurrency, useNetworkStyle } from './useCurrency'
import { addAppNoti } from '@/store/useNotiStore'
import { usePathGameName } from './usePathGameName'
import { buildQK, GameNameToQK, minimizeQK, packMaxValues, sortBigIntQK } from '@/lib/vault'
import useMaxValuesStore, { applyBufferToValue } from '@/store/useMaxValuesStore'
import { sendGameStoreUpdateByAddress, useDelayedLiveEntry } from '@/store/useGameStateStore'
import { type GameStateReducer } from '@/lib/fare/state'
import { useBalances } from './useBalances'
import { usePrivyService } from '../lib/privy/usePrivyService'
import { usePostLog } from '../lib/posthog/logging'
import { Axios, AxiosError } from 'axios'
import { useCreateWallet } from '@privy-io/react-auth'
import { useGeoblockModal } from './useGeoblockModal'

// Set a fee threshold to determine if the user can fund the transactions to setup quickplay
const USDC_SETUP_FEES_THRESHOLD = 2

export const useQuickplaySmartWallet = () => {
  const { createWallet } = useCreateWallet()
  const { smartWalletAddress, smartWalletClient, walletReady, authenticated, privyWallet } =
    useAuthedActiveWallet()
  const gameName = usePathGameName()
  const { appContracts, appChainConfig, hasSetupSmartWallet, isUsingSmartWallet } =
    useAppChainConfigStore(state => ({
      appContracts: state.appContracts,
      hasSetupSmartWallet: state.hasSetupSmartWallet,
      appChainConfig: state.appChainConfig,
      isUsingSmartWallet: state.isUsingSmartWallet,
    }))

  const {
    setIsSubmitting,
    setSUContractResults,
    setSubmittedSide,
    setSubmittedAmount,
    setEntryCount,
    hasApprovedSmartWalletGameContracts,
  } = useSUContractStore(state => ({
    setIsWithdrawing: state.setIsWithdrawing,
    setIsSubmitting: state.setIsSubmitting,
    setSubmittedSide: state.setSubmittedSide,
    setSubmittedAmount: state.setSubmittedAmount,
    setEntryCount: state.setEntryCount,
    setSUContractResults: state.setSUContractResults,
    hasApprovedSmartWalletGameContracts: state.hasApprovedSmartWalletGameContracts,
  }))
  const {
    ethUsdcPriceSCValue,
    ethUsdcPriceBufferPercentage,
    averageCallbackGasSCValue,
    averageCallbackGasBufferPercentage,
    aaCostMultiplierSCValue,
    aaCostMultiplierBufferPercentage,
  } = useMaxValuesStore()
  const { smartWalletAllowance, smartWalletBalance } = useCurrencyStore(state => ({
    smartWalletAllowance: state.allowances.currency,
    smartWalletBalance: state.balances.currency,
  }))
  const { fetchAndSetAllowance, fetchAllowance } = useCurrency()
  const { updateUserGameConfig } = usePrivyService()
  const { postlog } = usePostLog()
  const { showGeoblockModal } = useGeoblockModal()
  const networkStyle = useNetworkStyle()
  const send = useMemo(() => sendGameStoreUpdateByAddress(gameName), [gameName]) as
    | GameStateReducer
    | undefined

  const paymaster = useMemo(
    () =>
      createBicoPaymasterClient({
        paymasterUrl: appChainConfig.biconomyConfig.paymasterUrl,
      }),
    [appChainConfig]
  )

  const paymasterContext = useMemo(
    () =>
      networkStyle.networkName === 'ARBITRUM' ?
        toBiconomyTokenPaymasterContext({
          expiryDuration: 20000,
          feeTokenAddress: appContracts?.currency.address as `0x${string}`,
        })
      : undefined,
    [appContracts]
  )

  const sendSmartWalletTrialRegister = async (formData: any) => {
    if (!appContracts || !smartWalletClient) return

    setIsSubmitting(true)

    try {
      await updateUserGameConfig({
        gameName: gameName || '',
        count: Number(formData.numberOfEntries),
        side: formData.side,
      })

      const gameQKs = GameNameToQK[gameName].getQK(formData.side)
      const minimzedQKs = minimizeQK(gameQKs.q, gameQKs.k)
      const { sortedQ: q, sortedK: k } = sortBigIntQK(
        buildQK(minimzedQKs.q, minimzedQKs.k, BigInt(formData.numberOfEntries))
      )

      const maxEthUsdcPrice = applyBufferToValue(
        ethUsdcPriceSCValue || '0',
        ethUsdcPriceBufferPercentage || '0'
      )
      const maxAverageCallbackGas = applyBufferToValue(
        averageCallbackGasSCValue || '0',
        averageCallbackGasBufferPercentage || '0'
      )
      const maxAACostMultiplier = applyBufferToValue(
        aaCostMultiplierSCValue || '0',
        aaCostMultiplierBufferPercentage || '0'
      )

      const packedMaxValues = packMaxValues(
        maxEthUsdcPrice,
        maxAverageCallbackGas,
        maxAACostMultiplier
      )

      const trialRegisterData = encodeFunctionData({
        abi: parseAbi(['function trialRegister(address,uint256,uint256[],uint256[],uint256)']),
        functionName: 'trialRegister',
        args: [
          smartWalletAddress as any, // who
          parseUnits(String(formData.entryAmount), networkStyle.decimals), // multiplier
          q, // q
          k, // k
          packedMaxValues, // packedMaxValues
        ],
      })

      const trialRegisterTx = {
        to: appContracts.vault.address as `0x${string}`,
        data: trialRegisterData,
        paymaster,
        paymasterContext,
      }

      setSubmittedSide(formData.side)
      setSUContractResults(initialSUContractResults)

      // Alert UI about submitted transaction
      send?.({
        type: 'START',
        payload: {
          side: formData.side,
          entryAmount: formData.entryAmount,
          entryCount: formData.numberOfEntries,
          stopLoss: formData.stopLoss,
          stopGain: formData.stopGain,
        },
      })

      addAppNoti({
        msg: 'Entry submitted',
        type: 'success',
      })

      useDelayedLiveEntry.getState().setSubmittedTxHash(smartWalletAddress?.toLowerCase() || '')
      // const trialRegisterTxHash = await smartWalletClient.sendTransaction(trialRegisterTx)

      const trialRegisterTxHash = await smartWalletClient.sendTransaction({
        account: smartWalletClient.account,
        // chain: appChainConfig.chainDefinition,
        // data: trialRegisterData,
        calls: [trialRegisterTx],
        paymaster,
        paymasterContext,
      })

      // @NOTE: These are formData. needed for Plinko deltaAmountDisplayer
      setSubmittedAmount(String(formData.entryAmount))
      setEntryCount(formData.numberOfEntries)

      console.log('Sent trial register tx:', trialRegisterTxHash)

      postlog(`Submit aa received from bunlder for ${gameName}`, {
        loglevel: 'success',
        eventName: `submitted_aa_success_${gameName}_entry`,
        txHash: trialRegisterTxHash,
        walletAddress: smartWalletAddress,
        isPrivySmartWallet: isUsingSmartWallet,
        gamePayload: {
          side: formData.side,
          entryAmount: formData.entryAmount,
          entryCount: formData.numberOfEntries,
          stopLoss: formData.stopLoss,
          stopGain: formData.stopGain,
        },
      })
      window.__adrsbl.run(`aa_${gameName}_played`, true, [
        { name: 'pageUrl', value: location.pathname },
        { name: 'txHash', value: trialRegisterTx },
        { name: 'walletAddress', value: smartWalletAddress },
        {
          name: 'gamePayload',
          value: {
            side: formData.side,
            entryAmount: formData.entryAmount,
            entryCount: formData.numberOfEntries,
            stopLoss: formData.stopLoss,
            stopGain: formData.stopGain,
          },
        },
      ])
    } catch (err) {
      console.error(err)

      useDelayedLiveEntry.getState().setSubmittedTxHash('')
      setIsSubmitting(false)

      let errorMessage = 'Error with tx'
      if (err instanceof AxiosError) {
        if (err.response) {
          errorMessage = err.response?.data.error

          if (err.response?.data.isGeoRestricted) {
            showGeoblockModal(err.response?.data.region)
          }
        }
      }

      send?.({
        type: 'ERROR',
        payload: { errMsg: errorMessage },
      })

      addAppNoti({
        msg: errorMessage,
        type: 'error',
      })
    } finally {
      fetchAndSetAllowance(smartWalletAddress as `0x${string}`).catch(console.error)
    }
  }

  useEffect(() => {
    if (!privyWallet && walletReady && authenticated) {
      createWallet().then(console.log).catch(console.error)
    }
  }, [walletReady, authenticated, privyWallet])

  const batchQuickplaySetupTxs = useCallback(
    async (formData: any, onApprovalFinished: any = () => {}) => {
      if (!appContracts || !smartWalletClient) return

      setIsSubmitting(true)

      if (Number(smartWalletBalance) < USDC_SETUP_FEES_THRESHOLD)
        return addAppNoti({
          msg: 'Not enough USDC to setup quickplay',
          type: 'error',
        })

      const approveGameContractTxData = encodeFunctionData({
        abi: parseAbi(['function setAllowedContracts(address[],bool[])']),
        functionName: 'setAllowedContracts',
        args: [[appContracts.vault.address as `0x${string}`], [true]],
      })

      const approveGameContractTx = {
        to: appContracts.bankroll.address as `0x${string}`,
        data: approveGameContractTxData,
      }

      const approveAllowanceTxData = encodeFunctionData({
        abi: parseAbi(['function approve(address,uint256)']),
        functionName: 'approve',
        args: [
          appContracts.bankroll.address as `0x${string}`,
          utils.parseUnits(String(MAX_APPROVAL_AMOUNT), networkStyle.decimals).toBigInt(),
        ],
      })

      const approveAllowanceTx = {
        to: appContracts.currency.address as `0x${string}`,
        data: approveAllowanceTxData,
      }

      const calls = []

      if (networkStyle.networkName === 'ARBITRUM') {
        const proxyTokenPaymaster = '0x00000f7365ca6c59a2c93719ad53d567ed49c14c' as `0x${string}`
        const approveAllowanceProxyTokenMasterTxData = encodeFunctionData({
          abi: parseAbi(['function approve(address,uint256)']),
          functionName: 'approve',
          args: [
            proxyTokenPaymaster,
            utils.parseUnits(String(MAX_APPROVAL_AMOUNT), networkStyle.decimals).toBigInt(),
          ],
        })

        const approveAllowanceProxyTokenMasterTx = {
          to: appContracts.currency.address as `0x${string}`,
          data: approveAllowanceProxyTokenMasterTxData,
        }
        calls.push(approveAllowanceProxyTokenMasterTx)
        // const proxyTokenPaymasterTxHash = await smartWalletClient.sendTransaction({
        //   account: smartWalletClient.account,
        //   calls: [approveAllowanceProxyTokenMasterTx],
        //   paymaster,
        // })

        // console.log('Sent proxy token paymaster allowance setup tx:', proxyTokenPaymasterTxHash)
      }

      if (Number(smartWalletAllowance) <= 0) calls.push(approveAllowanceTx)
      if (hasApprovedSmartWalletGameContracts !== 'approved') calls.push(approveGameContractTx)

      try {
        const smartWalletSetupTxHash = await smartWalletClient.sendTransaction({
          account: smartWalletClient.account,
          calls,
          paymaster,
          paymasterContext,
        })

        useSUContractStore.setState({
          hasApprovedSmartWalletGameContracts: 'approved',
        })
        await fetchAndSetAllowance(smartWalletAddress as `0x{string}`)

        onApprovalFinished()

        await sendSmartWalletTrialRegister(formData)

        console.log('Sent smart wallet setup tx:', smartWalletSetupTxHash)
      } catch (err) {
        console.error(err)

        setIsSubmitting(false)

        addAppNoti({
          msg: 'Error with tx',
          type: 'error',
        })
      }
    },
    [
      smartWalletAddress,
      smartWalletClient,
      appContracts,
      networkStyle,
      smartWalletBalance,
      smartWalletAllowance,
      ethUsdcPriceSCValue,
      ethUsdcPriceBufferPercentage,
      averageCallbackGasSCValue,
      averageCallbackGasBufferPercentage,
      aaCostMultiplierSCValue,
      aaCostMultiplierBufferPercentage,
      appChainConfig,
    ]
  )

  return {
    hasSetupSmartWallet,
    smartWalletClient,
    smartWalletAddress,
    batchQuickplaySetupTxs,
    sendSmartWalletTrialRegister,
    smartWalletAllowance,
    smartWalletBalance,
  }
}
