import { LOCAL_STORAGE } from '@/constants/storage'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { usePrivy, useWallets } from '@privy-io/react-auth'

export const SmartWalletListener = () => {
  const { wallets } = useWallets()
  const { ready, authenticated } = usePrivy()
  const { setIsUsingSmartWallet } = useAppChainConfigStore(state => ({
    setIsUsingSmartWallet: state.setIsUsingSmartWallet,
  }))

  useEffect(() => {
    if (!ready || !authenticated || wallets.length === 0) return

    const hasExternalWallet =
      wallets.filter(wallet => wallet.connectorType !== 'embedded').length > 0
    const localStorageIsUsingSmartWallet = localStorage.getItem(LOCAL_STORAGE.IS_USING_SMART_WALLET)

    if (hasExternalWallet && !localStorageIsUsingSmartWallet) return setIsUsingSmartWallet(false)

    if (!hasExternalWallet) {
      setIsUsingSmartWallet(true)
    }
  }, [ready, authenticated, wallets])

  return null
}
