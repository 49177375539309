import { DicePage } from './pages/DicePage'
import { CoinFlipPage } from './pages/CoinFlipPage'
import { RPSPage } from './pages/RPSPage'
import { BombsPage } from './pages/BombsPage'
import SettingsPage from './pages/SettingsPage'
import { PointsPage } from './pages/PointsPage'
import { PlinkoPage } from './pages/PlinkoPage'
import { CrashPage } from './components/Games/Crash/CrashPage'
import { RoulettePage } from './components/Games/Roulette/RoulettePage'
import { isGameEnabled } from './constants/links'

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path='/'>
        <Route index element={<Navigate to='/coin-flip' replace />} />
        {isGameEnabled('coin flip') && <Route path='/coin-flip' element={<CoinFlipPage />} />}
        {isGameEnabled('dice') && <Route path='/dice' element={<DicePage />} />}
        {isGameEnabled('rps') && <Route path='/rps' element={<RPSPage />} />}
        {isGameEnabled('bombs') && <Route path='/bombs' element={<BombsPage />} />}
        {isGameEnabled('plinko') && <Route path='/plinko' element={<PlinkoPage />} />}
        {isGameEnabled('crash') && <Route path='/crash' element={<CrashPage />} />}
        {isGameEnabled('roulette') && <Route path='/roulette' element={<RoulettePage />} />}
        <Route path='/deathmatch' element={<PointsPage />} />
        <Route path='/settings' element={<SettingsPage />} />
        <Route path='*' element={<Navigate to='/coin-flip' replace />} />
      </Route>
    </Routes>
  )
}
