import { type PropsWithChildren } from 'react'
import { SFarePopover, SLinkTitlePopover, farePopoverVariants } from './styled'
import { AnimatePresence } from 'framer-motion'

interface IFarePopover
  extends PropsWithChildren<{
    isShowing: boolean
    textColor?: string
  }> {}

const Popover = ({
  isShowing,
  textColor = 'white',
  children,
  Component,
}: IFarePopover & { Component: any }) => {
  return (
    <AnimatePresence>
      {isShowing && (
        <Component
          $textColor={textColor}
          variants={farePopoverVariants}
          initial='initial'
          animate='animate'
          exit='exit'
        >
          {children}
        </Component>
      )}
    </AnimatePresence>
  )
}

export const FarePopover = (props: IFarePopover) => <Popover {...props} Component={SFarePopover} />

const LinkTitlePopover = (props: IFarePopover) => (
  <Popover {...props} Component={SLinkTitlePopover} />
)

export default LinkTitlePopover
